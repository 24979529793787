import { Footer } from "Containers/Layout/Footer";
import React from "react";
import { Header } from "Components/Header/Header";
import { Helmet } from "react-helmet";

export const PrivacyPolicy = () => {
  return (
    <div
      className="
    h-full
    flex
    flex-col
    text-theme-black
    bg-white
    mdm:block
    mdm:w-full
    "
    >
      <Helmet>
        <title>Petabox: Privacy Policy</title>
        <meta name="description" content="Petabox: Privacy Policy" />
      </Helmet>
      <Header />
      <main
        className="
      grow
      shrink-0
      basis-auto
      "
      >
        <div
          className="
        w-full
        max-w-[1130px]
        pt-0
        px-[15px]
        pb-[60px]
        my-0
        mx-auto
        2xlm:max-w-[830px]
        mdm:pt-[40px]
        "
        >
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h1
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-32
            text-40
            "
            >
              Petabox™ Privacy Policy
            </h1>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              This privacy statement sets out the privacy policy of Petabox (hereinafter «Petabox.io», «we») which is
              located at site https://petabox.io (hereinafter «the Website»).
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We are committed to protect and respect your privacy. This privacy policy (together with the Terms of Use)
              sets out the basis on which any personal data we collect from you, or that you provide to us, will be
              processed by us. The Personal Data Protection Act passed 15.02.2007 (Estonia) and the General Data
              Protection Regulation ((EU) 2016/679) provides rules for how we can collect, use, and disclose this
              information. By visiting the Website, you are agreeing to be legally bound by this privacy policy, so
              please read the following carefully to understand our views and practices regarding your data and how we
              will treat it. If any term in this Privacy Policy is unacceptable to you, please do not visit, access, or
              use petabox.io.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              1. What Information Do We Collect?
            </h2>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              1.1 Information You Provide
            </h3>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Contact Information
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We may collect personal and/or business contact information including your first name, last name, mailing
              address, telephone number, fax number, e-mail address or any other personal data you provide to us for the
              purposes of doing business with us.
            </p>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Service Account Information
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We collect information such as your username, authentication tokens and other necessary security
              information required for authentication and access to our services.
            </p>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Marketing and Communications
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We may also collect your preferences in receiving updates on our products or marketing material.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              1.2 Information Collected Automatically
            </h3>

            <h4
              className="
            font-semibold
            text-18
            "
            >
              Device & Technical Data
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We collect technical information when you visit our websites or use our mobile applications or services.
              This includes information such as your Internet Protocol (IP) address, your login data, type of mobile
              device you use, your device operating system and browser type, time zone setting and location, language, a
              unique device identifier, the address of a referring website, the path you take through our websites, and
              information about your session.
            </p>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Cookies
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We and our third party service providers use technologies such as cookies to collect information about the
              use of our website and services.
            </p>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Usage Data
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              During regular usage of our services, we collect information about the date and time of your logins and
              details of your activities on our platform.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              2. How We Use Your Personal Information?
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We use the information we collect in any of the following ways:
            </p>
            <ul
              className="
            smm:text-16
            pl-[20px]
            leading-[1.6]
            "
            >
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Carry out your requests, fulfill orders, and process payments for our products and services;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Communicate with you about your orders, purchases or accounts with us, including handling any requests,
                questions or comments you may have;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Provide online services to you, which includes our websites and/or mobile applications;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Provide customer support, including processing any concerns about our services;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Tell you about our products and services, competitions, offers, promotions or special events that we
                believe may interest you;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Personalize your experience in our online services;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Communicate with you about your activities with respect to our Services;
              </li>
            </ul>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              More information about how Google uses data can be found here{" "}
              <a
                className="
              text-theme-blue
              font-bold
              underline
              smm:inline-block
              smm:overflow-hidden
              smm:text-ellipsis
              smm:max-w-full
              hover:no-underline
              "
                target="_blank"
                href="https://www.google.com/policies/privacy/partners/"
              >
                {" "}
                https://www.google.com/policies/privacy/partners/
              </a>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
