import clsx from "clsx";
import { useMemo, useState } from "react";

export const Faq = () => {
  const items = useMemo(
    () => [
      {
        name: "Are there any docs or guides for Petabox Cloud Storage?",
        desc: (
          <>
            We do have product documentation containing technical details and guides for Petabox Cloud Storage. You can
            find all the documentation{" "}
            <a href="https://docs.petabox.io/" target="_blank" rel="noopener nofollow">
              here
            </a>
            .
          </>
        ),
      },
      {
        name: "Why are your prices radically cheaper than other clouds?",
        desc: (
          <>
            Petabox is an AI-based solution, packaging and integrating multiple cloud storage services into a single
            composite service. Being a data storage aggregator we use our AI to monitor all the prices pertaining to the
            entirety of the cloud storage market, enabling us to act as a sort-of real time arbitrage wholesaler for the
            infrastructures of major cloud services, while providing retail access.
          </>
        ),
      },
      {
        name: "Where exactly do you store your data?",
        desc: (
          <>
            We use most of the major cloud services to store our data in a distributed and secure way. We often use
            several clouds at once to achieve high data redundancy and availability. We store our data on sustainable
            cloud storages that include, but are not limited to Amazon Web Services, Microsoft Azure, Wasabi, Web3
            Services, Google Cloud, Digital Ocean, etc.
          </>
        ),
      },
      {
        name: "What are the limitations of Petabox Storage?",
        desc: (
          <>
            The maximum size per object is 2 TB. For a list of limitations please check the Petabox Cloud Storage
            documentation{" "}
            <a href="https://docs.petabox.io/" target="_blank" rel="noopener nofollow">
              here
            </a>
            .
          </>
        ),
      },
      {
        name: "How can I control my Petabox Cloud Storage features through the API / CLI?",
        desc: (
          <>
            You can use our 100% S3 compatible API to create and manage all aspects of Petabox Cloud Storage. You can
            also manage all the aspects through AWS S3 CLI with Petabox Cloud Storage.
          </>
        ),
      },
      {
        name: "Does Petabox support versioning of objects?",
        desc: <>Yes. Use API, AWS S3 CLI, Cyberduck or other clients supporting versioning to access this feature.</>,
      },
      {
        name: "Are there any additional fees for API calls? ",
        desc: (
          <>
            No, there are no hidden fees for any API calls: Petabox Cloud Storage has an all-inclusive rate so there are
            no extra charges.
          </>
        ),
      },
      {
        name: "What is your refund policy?",
        desc: (
          <>
            Excluding any specific mentions in limited time offers (for example: 30 day Free trial) we have a 14-day
            unconditional money back guarantee for monthly subscriptions and a 90-day unconditional money back guarantee
            for yearly subscriptions. If you are not happy with the service during the unconditional money back period
            relative to your plan and you wish to cancel, you will receive a full refund.
          </>
        ),
      },
    ],
    []
  );
  const [open, setOpen] = useState<number>(null);

  const handleOpen = (idx: number) => {
    return () => (open === idx ? setOpen(null) : setOpen(idx));
  };

  return (
    <div className="faq" id="faq">
      <div className="center">
        <h2 className="title h2">Frequently Asked Questions</h2>

        <dl className="faq__list accordion">
          {items.map((x, idx) => (
            <div key={idx} className={clsx("faq__item accordion__item", idx === open && "is-open")}>
              <dt onClick={handleOpen(idx)} className="faq__head accordion__head accordion__btn">
                {x.name}
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="m8.12 9 3.89 3.88L15.89 9a1 1 0 1 1 1.4 1.4L12.72 15a1 1 0 0 1-1.42 0L6.7 10.4a1 1 0 0 1 0-1.4 1.02 1.02 0 0 1 1.42 0Z" />
                </svg>
              </dt>

              <dd className="accordion__body">
                <p className="faq__body">{x.desc}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};
