import { Control, Path } from "react-hook-form";

export type Controlled<TData> = {
  control: Control<TData>;
  name: Path<TData>;
};

export const getControlledError = (formState: { errors: Record<string, any> }, name: string) => {
  const tokens = name.split(".");

  let value = formState.errors;
  for (const token of tokens) {
    if (value[token]) value = value[token];
  }

  return value && value.message ? (value.message as string) : undefined;
};
