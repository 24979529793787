import React, {useState} from "react";
import clsx from "clsx";

const initialState = {
  company: '',
  website: '',
  email: '',
  phone: '',
  storageSize: '',
  comment: ''
}

export const StartupForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formState, setFormState] = useState(initialState);

  const onChangeInput = (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const name = evt.currentTarget.name;
    const value = evt.currentTarget.value;

    setFormState({
      ...formState,
      [name]: value
    });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (formState) {
      fetch('https://api.petabox.io/startup', {
        method: 'POST',
        body: JSON.stringify(formState),
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then((status) => {
        setFormState(initialState);
        setModalOpen(true);
      }).catch((err) => {

      })
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="
      mb-[1.3em]
      smm:text-18
      mdm:text-20
      text-theme-black
      text-center
      font-semibold
      ">Getting started
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <span className="
        block
        text-16
        font-medium
        leading-[2]
        ">Company <sup className="text-theme-error">*</sup></span>
        <input required name="company" onChange={onChangeInput} value={formState.company} type="text"
               className="
               outline-0
               appearance-none
               overflow-auto
               smm:h-[55px]
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               h-[70px]
               text-theme-black
               pl-[1em]
               text-20
               smm:text-16
               border
               border-border-gray
               duration-300
               outline-0
               appearance-none
               overflow-auto
               "/>
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <span className="
        block
        text-16
        font-medium
        leading-[2]
        ">Website <sup className="text-theme-error">*</sup></span>
        <input required name="website" onChange={onChangeInput} value={formState.website} type="text"
               className="
               outline-0
               appearance-none
               overflow-auto
               smm:h-[55px]
               smm:text-16
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               h-[70px]
               text-theme-black
               pl-[1em]
               text-20
               border
               border-border-gray
               duration-300
               outline-0
               appearance-none
               overflow-auto"
               placeholder="https://"/>
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <span className="
        block
        text-16
        font-medium
        leading-[2]
        ">Email <sup className="text-theme-error">*</sup></span>
        <input required name="email" onChange={onChangeInput} value={formState.email} type="text" className="
               outline-0
               appearance-none
               overflow-auto
               smm:h-[55px]
               smm:text-16
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               h-[70px]
               text-theme-black
               pl-[1em]
               text-20
               border
               border-border-gray
               duration-300
               outline-0
               appearance-none
               overflow-auto
               "/>
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <span className="
        block
        text-16
        font-medium
        leading-[2]">Phone <sup className="text-theme-error">*</sup></span>
        <input name="phone" onChange={onChangeInput} value={formState.phone} type="text"
               className="
               outline-0
               appearance-none
               overflow-auto
               smm:h-[55px]
               smm:text-16
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               h-[70px]
               text-theme-black
               pl-[1em]
               text-20
               border
               border-border-gray
               duration-300
               outline-0
               appearance-none
               overflow-auto
               "/>
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <span className="
        block
        text-16
        font-medium
        leading-[2]">Prospective storage size <sup className="text-theme-error">*</sup></span>
        <select name="storageSize" onChange={onChangeInput} value={formState.storageSize}
          className="
               cursor-pointer
               outline-0
               appearance-none
               overflow-auto
               smm:h-[55px]
               smm:text-16
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               h-[70px]
               text-theme-black
               pl-[1em]
               text-20
               border
               border-border-gray
               duration-300
               bg-[url('./Images/select-arrow.svg')]
               bg-no-repeat
               bg-[center_right_1em]
               outline-0
               appearance-none
               overflow-auto
               ">
          <option value="" hidden>Please select</option>
          <option value="Less 10TB">&#60; 10TB</option>
          <option value="10TB-50TB">10TB-50TB</option>
          <option value="50TB-100TB">50TB-100TB</option>
          <option value="Over 100TB">&#62; 100TB</option>
        </select>
      </div>
      <div className="
      smm:mb-[30px]
      mdm:mb-[25px]
      mb-[2.4rem]
      ">
        <textarea value={formState.comment}
                  className="
               h-[150px]
               pt-[1em]
               resize-none
               outline-0
               appearance-none
               overflow-auto
               smm:h-[100px]
               smm:text-16
               mdm:h-[60px]
               block
               w-full
               rounded-[10px]
               text-theme-black
               pl-[1em]
               text-20
               border
               border-border-gray
               duration-300
               outline-0
               appearance-none
               overflow-auto
               "
                  placeholder="How do you want to use Petabox™?"
                  onChange={onChangeInput}
                  name="comment"
        />
      </div>
      <input type="submit" value="Send"
             className="
             mdm:min-w-[180px]
             mdm:h-[50px]
             inline-flex
             items-center
             justify-center
             text-16
             font-semibold
             h-[72px]
             min-w-[230px]
             rounded-[10px]
             outline-0
             border-0
             appearance-none
             duration-300
             bg-theme-blue
             hover:bg-theme-blue-hover
             active:bg-theme-blue-press
             text-white
             cursor-pointer
             shadow-[0px_16px_40px_rgba(0,35,102,0.1)]
             w-full
             outline-0
             appearance-none
             overflow-auto
      "/>
      {modalOpen &&
      <div className="
      z-[100]
      fixed
      w-full
      h-full
      bg-black/[.38]
      top-0
      left-0">
        <div className={clsx("confirm-popup rounded-[10px] bg-white ",
          "w-[90%] max-w-[750px] py-[60px] px-[20px] mdm:pb-[20px]",
          "fixed left-[50%] top-[50%]  z-[90] active block",
          !modalOpen && "translate-y-[-80%] translate-x-[-50%]",
          modalOpen && "translate-y-[-50%] translate-x-[-50%]"
        )}>
          <a onClick={() => setModalOpen(false)} className="
          smm:top-[20px]
          smm:right-[20px]
          absolute
          w-[15px]
          h-[15px]
          top-[30px]
          right-[30px]
          text-theme-gray
          group
          ">
            <span className="
            w-full
            h-[2px]
            bg-theme-gray
            absolute
            left-0
            top-1/2
            rotate-45
            group-hover:bg-theme-pink
            "> </span>
            <span className="
            w-full
            h-[2px]
            bg-theme-gray
            absolute
            left-0
            top-1/2
            rotate-[-45deg]
             group-hover:bg-theme-pink
            "> </span>
          </a>
          <div className="text-center">
            <div className="
            mb-[6rem]
            smm:mb-[40px]
            "><img className="inline" src={require("../../Images/check_red_circle.svg").default} alt=""/></div>
            <div className="
            smm:mb-[40px]
            mb-[1em]
            smm:text-24
            mdm:text-[3.2rem]
            text-[3.6rem]
            leading-[1.3]
            font-semibold
            ">Thank you!
            </div>
            <p className="
            text-20
            
            2xlm:text-18
            
            mdm:text-16
            
            smm:text-14
            
            text-theme-dark-blue
            mb-[2em]
            smm:mb-[40px]
            ">Thank you for the application.<br className="smm:hidden"/> We will send you a registration confirmation
              soon.
            </p>
            <a onClick={() => setModalOpen(false)}
               className="
               smm:mb-[40px]
               btn
               inline-flex
               items-center
               justify-center
               text-16
               font-semibold
               h-[72px]
               min-w-[230px]
               rounded-[10px]
               outline-0
               border-0
               appearance-none
               duration-200
               mdm:min-w-[180px]
               mdm:h-[50px]
               smm:w-full
               bg-theme-blue
               text-white
               shadow-[0px 16px 40px theme-grey-shadow]
               hover:bg-theme-blue-hover
               active:bg-theme-blue-press
            ">Ok</a>
          </div>
        </div>
      </div>
      }
    </form>
  )
}