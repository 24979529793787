import { Header } from "Components/Header/Header";
import { Footer } from "Containers/Layout/Footer";
import React from "react";
import {Helmet} from "react-helmet";

export const Sla = () => {
  return (
    <div
      className="
    h-full
    flex
    flex-col
    text-theme-black
    text-[10rem]
    bg-white
    mdm:block
    mdm:w-full
    "
    >
      <Header />
      <Helmet>
        <title>Petabox: Service Level Agreement</title>
        <meta name="description" content="Petabox: Service Level Agreement"/>
      </Helmet>
      <main
        className="
      grow
      shrink-0
      basis-auto
        "
      >
        <div
          className="
        w-full
        max-w-[1130px]
        pt-0
        px-[15px]
        pb-[60px]
        my-0
        mx-auto
        2xlm:max-w-[830px]
        mdm:pt-[40px]
        "
        >
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h1
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-32
            text-40
            "
            >
              Petabox™ Service Level Agreement
            </h1>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Last updated: Feb 14, 2022
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              This Petabox Service Level Agreement (“SLA”) sets forth the service levels provided by Petabox with
              respect to Petabox's services (the “Petabox Service”) agreed between the applicable Petabox entity
              (“Petabox ”, “we”, “us” or “ours”) and you as a user of our services (“Customer”, “you” or “your”).
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              The following definitions apply to this SLA:
            </h3>
            <ul
              className="
            smm:text-16
            pl-[20px]
            leading-[1.6]
            "
            >
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“User Account”</b> is an individual user of one or more Covered Services paid for by a Customer. Each
                User Account has a unique email address.
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“User Downtime”</b> means a User Account is not able to access a Covered Service to log in, upload
                data, select files, or download completed data restores/snapshots unless excluded by the SLA Exclusions
                below.
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“User Downtime Period”</b> means a period of one or more consecutive minutes of User Downtime.
                Partial or intermittent minutes of User Downtime will not be counted.
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“Monthly User Downtime”</b> is the total of all User Downtime Periods in a given month.
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“Monthly User Uptime Percentage”</b> is computed as the total number of minutes in a given month,
                minus the Monthly User Downtime for that month, divided by the total number of minutes in that month.
                <table
                  className="
                border
                border-black
                w-full
                table-fixed
                p-0
                my-[20px]
                mx-0
                "
                >
                  <thead>
                    <tr>
                      <th
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        Monthly Uptime Percentage
                      </th>
                      <th
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        Service Credit Percentage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        Equal to or greater than 99.0% but less than 99.9%
                      </td>
                      <td
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        10%
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        less than 99.0%
                      </td>
                      <td
                        className="
                    p-[0.5em]
                    border-b-[1px]
                    border-r-[1px]
                    border-black
                    box-border
                    "
                      >
                        25%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                <b>“Service Credit”</b> is the User Credit Percentage times the monthly amount charged for a User
                Account. For license terms greater than 1 month (e.g. yearly and two year licenses), the monthly amount
                is the amount charged for the license divided by the number of months in the license term.
              </li>
            </ul>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              The Petabox Service Level Objective
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              During the term of this SLA, Petabox agrees to meet or exceed a Service Level Objective (“SLO”) of 99.9%
              for the Monthly User Uptime Percentage. If Petabox does not meet or exceed the SLO, and if the Customer
              meets its obligations under this SLA detailed below, the Customer will be eligible to receive a Service
              Credit as described above. This SLA states Customer’s sole and exclusive remedy for any failure by Petabox
              to meet the SLO.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              Customer Obligations Under SLA
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              To receive a Service Credit, Customers must submit a claim via a support ticket at Petabox Support.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Claims must include:
            </p>
            <ul
              className="
            smm:text-16
            pl-[20px]
            leading-[1.6]
            "
            >
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                SLO violation term month/year in the title of the support ticket
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                The User Account for which the Service Credit is sought
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                An estimate of the Monthly User Downtime the Customer is claiming for the User Account
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                The dates and times of each User Downtime incident.
              </li>
            </ul>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              Credit Request and Payment Procedures
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              To receive a Service Credit, you must submit a claim to support@petabox.io
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              To be eligible, the credit request must be received by us by the end of the second billing cycle after
              which the incident occurred and must clearly
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (a) indicate in the subject line that it is a Service Credit request;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (b) specify the dates and times of each incident of non-zero Error Rates that you are claiming;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (c) document the errors and corroborate your claimed outage (any confidential or sensitive information in
              these logs should be removed or replaced with asterisks).
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If your request is validated by us, then we will issue the Service Credit to you within one billing cycle
              following the month in which your request is confirmed by us. If you do not timely submit a written
              request for a Service Credit, you forfeit all rights to receive a Service Credit for the billing cycle in
              question.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              A Service Credit will be applicable and issued only if the credit amount for the applicable monthly
              billing cycle is greater than one dollar ($1 USD). We will apply any Service Credits only against future
              payments otherwise due from you for the Services. We may elect to issue the Service Credit to the credit
              card you used to pay the fees during the billing cycle in which the error occurred. Service Credits may
              not be transferred or applied to any account other than the account in which the error occurred, as set
              forth below.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              SLA Exclusions
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              This SLA does not apply to any unavailability, suspension or termination of the Petabox Service, or any
              other Petabox Service performance issues:
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (i) caused by factors outside of our reasonable control, including any force majeure event or Internet
              access or related problems beyond the demarcation point of the Petabox Service;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (ii) that result from any actions or inactions of you or any third party;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (iii) that result from your equipment, software or other technology and/or third party equipment, software
              or other technology (other than third party equipment within our direct control);
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (iv) that result from scheduled or emergency maintenance activities for the Services;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (v) arising from our suspension and termination of your right to use the Petabox Service in accordance
              with the Customer Agreement;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (vi) Petabox’s scheduled maintenance, which will be notified to you via the Petabox Service or at
              https://status.petabox.io, as well as any unscheduled emergency maintenance.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Further, all test, development, beta, sandbox and other non-production environments are expressly excluded
              from this SLA, and no Service Credits shall be available for unavailability of any such environment.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
