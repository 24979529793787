import Calculator from "Components/Calculator/Calculator";
import { PRICE } from "price";

export const CalculatorBlock = () => {
  return (
    <div className="calculator" id="calculator">
      <div className="center">
        <h2 className="title h2">
          Compare cloud storage costs. <br />
          How much storage is right for you?
        </h2>

        {/* <div className="calculator__box"> */}
        <Calculator price={PRICE} />
        {/* </div> */}

        <p className="calculator__note">
          Each scenario is a little different, and this calculator uses competitive providers’ published rates for high
          storage tiers in low cost regions — the results displayed here are estimates that require further diligence on
          customer side. <br />
          <strong>All the calculations are made for the "Pay-as-you-go" tariff plan.</strong>
        </p>
      </div>
    </div>
  );
};
