export const StorageTools = () => {
  return (
    <div className="storage-tools">
      <div className="center">
        <h2 className="title h2">Our storage works with tools you already use</h2>

        <ul className="storage-tools__list">
          <li className="storage-tools__item">
            <figure>
              <picture>
                <source
                  srcSet="/img/storage-tools/aws_cli--213x200.webp"
                  type="image/webp"
                  media="(max-width: 940px)"
                />
                <source srcSet="/img/storage-tools/aws_cli--213x200.png" media="(max-width: 940px)" />

                <source
                  srcSet="/img/storage-tools/aws_cli--161x100.webp 1x, /img/storage-tools/aws_cli--322x200.webp 2x"
                  type="image/webp"
                  // media="(max-width: 1600px)"
                />
                <source
                  srcSet="/img/storage-tools/aws_cli--161x100.png 1x, /img/storage-tools/aws_cli--322x200.png 2x"
                  // media="(max-width: 1600px)"
                />

                <source
                  srcSet="/img/storage-tools/aws_cli--221x100.webp 1x, /img/storage-tools/aws_cli--442x200.webp 2x"
                  type="image/webp"
                />
                <source srcSet="/img/storage-tools/aws_cli--221x100.png 1x, /img/storage-tools/aws_cli--442x200.png 2x" />

                <img
                  src="/img/storage-tools/aws_cli--221x100.png"
                  width="221"
                  height="100"
                  alt="AWS CLI"
                  loading="lazy"
                />
              </picture>

              <figcaption>AWS CLI</figcaption>
            </figure>
          </li>

          <li className="storage-tools__item">
            <figure>
              <picture>
                <source srcSet="/img/storage-tools/rclone--213x200.webp" type="image/webp" media="(max-width: 940px)" />
                <source srcSet="/img/storage-tools/rclone--213x200.png" media="(max-width: 940px)" />

                <source
                  srcSet="/img/storage-tools/rclone--161x100.webp 1x, /img/storage-tools/rclone--322x200.webp 2x"
                  type="image/webp"
                  media="(max-width: 1600px)"
                />
                <source
                  srcSet="/img/storage-tools/rclone--161x100.png 1x, /img/storage-tools/rclone--322x200.png 2x"
                  media="(max-width: 1600px)"
                />

                <source
                  srcSet="/img/storage-tools/rclone--221x100.webp 1x, /img/storage-tools/rclone--442x200.webp 2x"
                  type="image/webp"
                />
                <source srcSet="/img/storage-tools/rclone--221x100.png 1x, /img/storage-tools/rclone--442x200.png 2x" />

                <img
                  src="/img/storage-tools/rclone--221x100.png"
                  width="221"
                  height="100"
                  alt="Rclone"
                  loading="lazy"
                />
              </picture>

              <figcaption>Rclone</figcaption>
            </figure>
          </li>

          <li className="storage-tools__item">
            <figure>
              <picture>
                <source
                  srcSet="/img/storage-tools/s3_browser--213x200.webp"
                  type="image/webp"
                  media="(max-width: 940px)"
                />
                <source srcSet="/img/storage-tools/s3_browser--213x200.png" media="(max-width: 940px)" />

                <source
                  srcSet="/img/storage-tools/s3_browser--161x100.webp 1x, /img/storage-tools/s3_browser--322x200.webp 2x"
                  type="image/webp"
                  media="(max-width: 1600px)"
                />
                <source
                  srcSet="/img/storage-tools/s3_browser--161x100.png 1x, /img/storage-tools/s3_browser--322x200.png 2x"
                  media="(max-width: 1600px)"
                />

                <source
                  srcSet="/img/storage-tools/s3_browser--221x100.webp 1x, /img/storage-tools/s3_browser--442x200.webp 2x"
                  type="image/webp"
                />
                <source srcSet="/img/storage-tools/s3_browser--221x100.png 1x, /img/storage-tools/s3_browser--442x200.png 2x" />

                <img
                  src="/img/storage-tools/s3_browser--221x100.png"
                  width="221"
                  height="100"
                  alt="S3 browser"
                  loading="lazy"
                />
              </picture>

              <figcaption>S3 browser</figcaption>
            </figure>
          </li>

          <li className="storage-tools__item">
            <figure>
              <picture>
                <source
                  srcSet="/img/storage-tools/cyberduck--213x200.webp"
                  type="image/webp"
                  media="(max-width: 940px)"
                />
                <source srcSet="/img/storage-tools/cyberduck--213x200.png" media="(max-width: 940px)" />

                <source
                  srcSet="/img/storage-tools/cyberduck--161x100.webp 1x, /img/storage-tools/cyberduck--322x200.webp 2x"
                  type="image/webp"
                  media="(max-width: 1600px)"
                />
                <source
                  srcSet="/img/storage-tools/cyberduck--161x100.png 1x, /img/storage-tools/cyberduck--322x200.png 2x"
                  media="(max-width: 1600px)"
                />

                <source
                  srcSet="/img/storage-tools/cyberduck--221x100.webp 1x, /img/storage-tools/cyberduck--442x200.webp 2x"
                  type="image/webp"
                />
                <source srcSet="/img/storage-tools/cyberduck--221x100.png 1x, /img/storage-tools/cyberduck--442x200.png 2x" />

                <img
                  src="/img/storage-tools/cyberduck--221x100.png"
                  width="221"
                  height="100"
                  alt="Cyberduck"
                  loading="lazy"
                />
              </picture>

              <figcaption>Cyberduck</figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  );
};
