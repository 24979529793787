import { FormBlock } from "Components/Form/FormBlock";
import { Link } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";
import { useShowApiError } from "hooks/useShowApiError";
import { useForm } from "react-hook-form";
import { SignUpB2bModel } from "Models/SignUpB2b";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { registerCorporate } from "../../../Services/auth-service";
import { changeLocation, sendMetricEvent } from "../../../Helpers/common";
import { handleApiError } from "../../../Helpers/handeApiError";
import { signUpB2bFormSchema } from "../signUpB2bFomSchema";
import { AsyncButton } from "../../../Components/Buttons/AsyncButton";
import { ControlledSelect } from "../../../Components/controlled-ui/ControlledSelect";
import { countriesList } from "../../../Components/CustomSelector/countriesList";
import { storageAmount } from "../../../Components/CustomSelector/storageAmount";
import { OptionType } from "Components/Form/FormSelector";

type FormType = Omit<SignUpB2bModel, "storageAmount" | "country"> & {
  storageAmount: OptionType;
  country: OptionType;
};

export const FirstScreenB2b = () => {
  const { control, trigger, setError, getValues } = useForm<FormType & { confirmPassword: string }>({
    resolver: yupResolver(signUpB2bFormSchema),
  });

  const [apiError, setApiError] = useShowApiError();

  const onSubmit = async () => {
    const valid = await trigger();
    setApiError("");
    if (!valid) return;
    const {
      country: { label: countryLabel },
      storageAmount: { label: storageAmountLabel },
      ...data
    } = getValues();

    try {
      const response = await registerCorporate({
        ...data,
        country: countryLabel,
        dataVolume: storageAmountLabel,
      });
      if (response.status === 409) {
        setError("email", { type: "custom", message: response.data.message });
        return;
      } else if (response.user) {
        sendMetricEvent("Form - reg_page");
        changeLocation(`${window.origin}/dashboard`);
      }
    } catch (e) {
      const err = handleApiError(e);
      setApiError(err.message);
    }
  };

  return (
    <div className="first-screen first-screen-corporate">
      <div className="center">
        <div className="first-screen__flexB2b">
          <div className="first-screen__contentB2b mb-[36px]">
            <p className="first-screen__titleB2b font-bold text-[64px] leading-[88px]">
              Start a 30-day free trial or&nbsp;buy&nbsp;now
            </p>
            <p className="first-screen__textB2b text-center lg:text-left  mb-[24px]">
              Already you ready to revolutionize your cloud storage? <br className="hidden sm:block" />
              Store more data for less money at faster speeds.
            </p>
            <p className="first-screen__textB2b text-center sm:text-left">
              <ul className="list-items">
                <li className="list-item-square">Get full access to Petabox free for 30 days;</li>
                <li className="list-item-square">Store up to 1 TB;</li>
                <li className="list-item-square">No credit card required;</li>
                <li className="list-item-square">No auto-charge after the trial ends.</li>
              </ul>
            </p>
          </div>
          <div className="first-screen__offer">
            <FormBlock>
              <>
                <div className="flex flex-col md:grid-cols-2 gap-[24px] mb-[24px]">
                  <ControlledInput type="text" control={control} name="firstName" required label="First name" />
                  <ControlledInput type="text" control={control} name="lastName" required label="Last name" />
                  <ControlledInput type="text" control={control} name="companyName" label="Company name" />
                  <ControlledInput type="text" control={control} name="email" required label="Work Email" />
                </div>
                <div className="grid grid-cols-1 gap-[24px]">
                  <ControlledSelect
                    control={control}
                    name="country"
                    options={countriesList}
                    label="Country"
                    required
                    placeholder="Choose a country"
                  />
                  <ControlledInput type="text" control={control} name="phone" label="Phone" />
                  <ControlledSelect
                    control={control}
                    name="storageAmount"
                    options={storageAmount}
                    label="Storage amount"
                    placeholder="Please select"
                    required
                  />
                  <ControlledInput type="password" control={control} name="password" required label="Password" />
                  <ControlledInput
                    type="password"
                    control={control}
                    name="confirmPassword"
                    required
                    label="Confirm Password"
                  />
                  {apiError && <small className="text-red-500">{apiError}</small>}
                  <AsyncButton id="signup" onClick={onSubmit}>
                    {"Start your Free Trial"}
                  </AsyncButton>
                  <p className="text-[12px] leading-[24px]">
                    Already a subscriber?{" "}
                    <Link className="text-theme-blue underline hover:no-underline" to={routesEnum.signin}>
                      Sign in
                    </Link>
                  </p>
                </div>
              </>
            </FormBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
