export const changeLocation = (url: string) => (window.location.href = url);

export const sleep = async (ms: number, cb?: () => void) => {
  return new Promise((res) => {
    setTimeout(() => res(cb?.()), ms);
  });
};

export const sendMetricEvent = (eventName: string) => {
  try {
    // @ts-ignore
    window.dataLayer.push({
      event: eventName,
    });
  } catch (e) {
    console.error(e);
  }
};
