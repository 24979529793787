import { config } from "common/config";
import { getSearchParams } from "Helpers/searchParams";
import { selector } from "recoil";

// prevent scam token from other origins
const VALID_REDIRECT_DOMAINS = ["https://petabox.io", "https://petabox.dev"];

export const checkRedirectCurrentLocation = selector({
  key: "checkRedirectCurrentLocation",
  get: () => {
    const { redirectTo } = getSearchParams(window.location.href);
    if (!redirectTo) return undefined;
    try {
      const { origin } = new URL(redirectTo);
      const validUrl = !config.isProd
        ? true
        : VALID_REDIRECT_DOMAINS.includes(origin);
      if (!validUrl) return false;
      return redirectTo;
    } catch {
      return undefined;
    }
  },
});
