export const PRICE = {
    "standard": {
        "coldstack": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 49.152
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 12.288
                }
            ]
        },
        "amazonS3": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": 50,
                    "price": 282.624
                },
                {
                    "fromTb": 50,
                    "toTb": 500,
                    "price": 270.336
                },
                {
                    "fromTb": 500,
                    "toTb": null,
                    "price": 258.048
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1105.92
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1044.48
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        },
        "googleCloud": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 319.488
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 1,
                    "price": 1474.56
                },
                {
                    "fromTb": 1,
                    "toTb": 10,
                    "price": 1351.68
                },
                {
                    "fromTb": 10,
                    "toTb": null,
                    "price": 983.04
                }
            ]
        },
        "azureStorage": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": 50,
                    "price": 221.18399999999997
                },
                {
                    "fromTb": 50,
                    "toTb": 500,
                    "price": 212.5824
                },
                {
                    "fromTb": 500,
                    "toTb": null,
                    "price": 203.9808
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1075.1999999999998
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1019.904
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        }
    },
    "intelligentTiering": {
        "coldstack": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 43.008
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 12.288
                }
            ]
        },
        "amazonS3": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": 50,
                    "price": 282.624
                },
                {
                    "fromTb": 50,
                    "toTb": 500,
                    "price": 270.336
                },
                {
                    "fromTb": 500,
                    "toTb": null,
                    "price": 258.048
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1105.92
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1044.48
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        },
        "googleCloud": {
            "available": false
        },
        "azureStorage": {
            "available": false
        }
    },
    "standardIA": {
        "coldstack": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 36.864000000000004
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 18.432000000000002
                }
            ]
        },
        "amazonS3": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 153.60000000000002
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1105.92
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1044.48
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        },
        "googleCloud": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 122.88
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 1,
                    "price": 1597.44
                },
                {
                    "fromTb": 1,
                    "toTb": 10,
                    "price": 1474.56
                },
                {
                    "fromTb": 10,
                    "toTb": null,
                    "price": 1105.92
                }
            ]
        },
        "azureStorage": {
            "available": false
        }
    },
    "glacier": {
        "coldstack": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 24.576
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 45.4656
                }
            ]
        },
        "amazonS3": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 49.152
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1105.92
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1044.48
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        },
        "googleCloud": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 86.016
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 1,
                    "price": 1720.3200000000002
                },
                {
                    "fromTb": 1,
                    "toTb": 10,
                    "price": 1597.44
                },
                {
                    "fromTb": 10,
                    "toTb": 0,
                    "price": 1228.8000000000002
                }
            ]
        },
        "azureStorage": {
            "available": false
        }
    },
    "deepArchive": {
        "coldstack": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 12.16512
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 184.32
                }
            ]
        },
        "amazonS3": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 12.16512
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 10,
                    "price": 1105.92
                },
                {
                    "fromTb": 10,
                    "toTb": 50,
                    "price": 1044.48
                },
                {
                    "fromTb": 50,
                    "toTb": 150,
                    "price": 860.1600000000001
                },
                {
                    "fromTb": 150,
                    "toTb": null,
                    "price": 614.4000000000001
                }
            ]
        },
        "googleCloud": {
            "available": true,
            "storage": [
                {
                    "fromTb": 0,
                    "toTb": null,
                    "price": 49.152
                }
            ],
            "download": [
                {
                    "fromTb": 0,
                    "toTb": 1,
                    "price": 2088.96
                },
                {
                    "fromTb": 1,
                    "toTb": 10,
                    "price": 1966.08
                },
                {
                    "fromTb": 10,
                    "toTb": null,
                    "price": 1597.44
                }
            ]
        },
        "azureStorage": {
            "available": false
        }
    }
}