import { yupResolver } from "@hookform/resolvers/yup";
import { parseError } from "common/parseError";
import { AsyncButton } from "Components/Buttons/AsyncButton";
import { ButtonSocial } from "Components/Buttons/ButtonSocial";
import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { signInFormSchema } from "Containers/SignIn/signInFormSchema";
import { changeLocation } from "Helpers/common";
import { Google } from "Images/Icons/Google";
import { PetaboxIcon } from "Images/Icons/PetaboxIcon";
import { SignInModel } from "Models/SignIn";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routesEnum } from "Routes/routesEnum";
import { login, loginGoogle } from "Services/auth-service";
import { checkRedirectCurrentLocation } from "store/checkRedirectCurrentLocation";
import { Helmet } from "react-helmet";
import { SelectedPlanState } from "store/store";

export const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const redirectUrl = useRecoilValue(checkRedirectCurrentLocation);
  const { control, trigger, handleSubmit } = useForm<SignInModel>({
    resolver: yupResolver(signInFormSchema),
  });

  const tariffId = useRecoilValue(SelectedPlanState);

  const onSubmit = async (formData: SignInModel) => {
    setErrorMessage(null);
    const valid = await trigger();

    if (!valid) return;

    try {
      const rep = await login(formData);

      if (redirectUrl) {
        return changeLocation(`${redirectUrl}?token=${rep.accessToken}`);
      }
      changeLocation(`${window.origin}/dashboard`);
    } catch (e) {
      parseError(e, setErrorMessage);
    }
  };

  return (
    <div className="flex flex-col py-6 px-5 md:bg-theme-blue min-h-screen md:items-center md:justify-between md:py-[62px] md:px-[145px] md:gap-y-[10vh]">
      <Helmet>
        <title>Petabox: Sign In</title>
        <meta name="description" content="Petabox: Sign In" />
      </Helmet>
      <Link to={routesEnum.index}>
        <PetaboxIcon className="hidden md:block text-white h-[24px] mx-auto" fill="currentColor" />
      </Link>
      <div className="flex flex-col md:w-[478px] md:bg-white p-[40px] md:rounded-10">
        <Link to={routesEnum.index}>
          <PetaboxIcon className="text-theme-blue h-[24px] mx-auto mb-[87px] md:hidden" fill="currentColor" />
        </Link>
        <h1 className="text-input-gray2 text-28 leading-[32px] font-second-font font-semibold">
          Sign in to your account
        </h1>
        <p className="text-16 leading-[32px] mb-[24px] font-semibold">
          Don't have an account?{" "}
          <Link className="text-theme-blue font-semibold" to={routesEnum.signup}>
            Sign up
          </Link>
        </p>

        <div className="flex flex-col gap-y-[24px]">
          <ControlledInput
            className="rounded-[8px]"
            type="email"
            control={control}
            name="email"
            required
            label="Email"
            autocomplete
          />
          <ControlledInput
            className="rounded-[8px]"
            type="password"
            control={control}
            name="password"
            required
            label="Password"
            autocomplete
          />
        </div>
        <Link
          className="text-[12px] leading-[24px] font-semibold text-theme-blue block pl-4 mb-[24px]"
          to={routesEnum.recoverPassword}
        >
          Forgot your password?
        </Link>
        {errorMessage && <small className="text-sm text-center text-theme-error mb-6">{errorMessage}</small>}
        <AsyncButton className="font-second-font rounded-[8px] mt-0" onClick={handleSubmit(onSubmit)}>
          Sign In
        </AsyncButton>
        <span className="font-second-font text-input-gray2 text-14 font-semibold text-center py-[5px] leading-[30px]">
          or
        </span>

        <div className="flex flex-col gap-y-4 mb-[122px] md:mb-0 md:flex-row gap-x-5">
          <ButtonSocial className="md:text-12 gap-x-[10px] md:w-full" onClick={() => loginGoogle(tariffId)}>
            <Google />
            Continue with Google
          </ButtonSocial>
          {/* <ButtonSocial className="md:text-12 gap-x-[10px] md:w-full" onClick={loginFB}>
            <Facebook />
            Continue with Facebook
          </ButtonSocial> */}
        </div>
      </div>

      <span className="text-theme-gray text-14 md:text-white text-center">Petabox © 2022. All Rights Reserved.</span>
    </div>
  );
};
