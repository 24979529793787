import { Link } from "react-router-dom";

export const FirstScreen = () => {
  return (
    <div className="first-screen">
      <div className="wrapperCenter">
        <div className="center">
          <div className="first-screen__flex">
            <div className="first-screen__content">
              <p className="first-screen__discounter">
                <span className="first-screen__discounter-text">S3 Compatible Object Storage</span>
              </p>

              <p className="first-screen__title h1">
                Store more,
                <br />
                pay less
              </p>

              <p className="first-screen__text">
                Cloud Storage with a ransomware protection
                <br /> and 3x data replication, which costs 10x less
                <br /> than Amazon S3.
              </p>

              <div className="first-screen__btn">
                <Link className="btn btn--big btn--white" to={{ pathname: "/signup" }} id="front_button_left">
                  Start a free 30 day Trial
                </Link>
              </div>
            </div>
            <div className="first-screen__offer"></div>
          </div>
        </div>
        <div className={"first-screen__leftBlock"}>
          <img className={"first-screen__dashboardDesktopImg"} src="/img/first-screen/desktop-slice.png" alt="" />

          <img className={"first-screen__dashboardTabletImg"} src="/img/first-screen/Tablet-min.png" alt="" />
          <img
            className={"first-screen__dashboardMobileImgMobile"}
            src="/img/first-screen/dashboardMobileImg-min.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
