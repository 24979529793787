import { FormInput, FormInputProps } from "Components/Form/FormInput";
import { Controller } from "react-hook-form";
import { Controlled, getControlledError } from "./common";

type ControlledInputProps<T> = Omit<FormInputProps, "onChange" | "value"> & Controlled<T>;

export const ControlledInput = <T,>({ control, name, ...inputProps }: ControlledInputProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, formState }) => {
        return (
          <FormInput
            error={getControlledError(formState, name)}
            value={value as string}
            name={name}
            onChange={({ target: { value } }) => onChange(value)}
            {...inputProps}
          />
        );
      }}
    />
  );
};
