import { config } from "common/config";
import { SubPlanEnum } from "common/consts";
import { LocalStorage } from "Helpers/localStorage";
import { RegisterResponse } from "Models/RegisterResponse";
import { ResetPasswordRequest } from "Models/ResetPasswordRequest";
import { SignInModel } from "Models/SignIn";
import { SignUpModel } from "Models/SignUp";
import { UserProfile } from "Models/UserModel";
import { apiGet, clientApi } from "Services/clientApi";
import { SignUpB2bModel } from "../Models/SignUpB2b";

export const register = (formData: SignUpModel): Promise<RegisterResponse> => {
  return clientApi.post<RegisterResponse>("/auth/registration", formData).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
    }
    return response.data;
  });
};

export const registerCorporate = (formData: SignUpB2bModel): Promise<RegisterResponse> => {
  return clientApi.post<RegisterResponse>("/auth/registration/corporate", formData).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
    }
    return response.data;
  });
};

export const login = (formData: SignInModel) => {
  return clientApi.post<RegisterResponse>("/auth/login", formData).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
    }

    return response.data;
  });
};

export const loginFB = (tariffId?: SubPlanEnum) => {
  let newUrl = `${config.authApiUrl}/auth/facebook`;

  if (tariffId) {
    newUrl += `?tariffId=${tariffId}`;
  }

  window.location.href = newUrl;
};
export const loginGoogle = (tariffId?: SubPlanEnum) => {
  let newUrl = `${config.authApiUrl}/auth/google`;

  if (tariffId) {
    newUrl += `?tariffId=${tariffId}`;
  }

  window.location.href = newUrl;
};
export const logout = () => LocalStorage.deleteItem("accessToken");

export const recoverPassword = (formData: { email: string }) => {
  return clientApi.post<{ email: string }>("/auth/send-password-reset-email", formData).catch((e) => e);
};

export const resetPassword = (formData: ResetPasswordRequest) => {
  return clientApi.post<ResetPasswordRequest>("/auth/verify-password-reset", formData).catch((e) => e);
};

export const resendEmail = (formData: { verificationId: string }) =>
  clientApi.post(`/auth/send-email-verification-again?verificationId=${formData.verificationId}`).catch((e) => e);

export const getUserProfile = async () => apiGet<UserProfile>("/me");
