export enum routesEnum {
  index = "/",
  sla = "/sla",
  privacyPolicy = "/privacy-policy",
  cookiePolicy = "/cookies-policy",
  termsOfService = "/terms-of-service",
  startup = "/startup",
  signup = "/signup",
  signin = "/signin",
  recoverPassword = "/recover-password",
  resetPassword = "/reset-password",
  providerRedirect = "/provider-redirect",
  emailRegistration = "/email-registration",
  emailVerify = "/email-verify",
  b2b = "/b2b",
  agreement = "/agreement"
}
