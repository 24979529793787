import axios from "axios";

export const parseError = (e: unknown, setError?: (message: string) => void) => {
  if (axios.isAxiosError(e)) {
    const error = e.response.data as { message: string; statusCode: number };
    setError?.(error.message);
    return error;
  }
  const defaultError = { statusCode: "InternalError", message: "Internal Error" };
  setError?.(defaultError.message);
  return defaultError;
};
