export const Eye = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1786_5680)">
            <path d="M23.2708 9.4183C21.7198 6.8923 18.1918 2.6543 11.9998 2.6543C5.80781 2.6543 2.27981 6.8923 0.728807 9.4183C0.249335 10.1938 -0.00463867 11.0875 -0.00463867 11.9993C-0.00463867 12.9111 0.249335 13.8048 0.728807 14.5803C2.27981 17.1063 5.80781 21.3443 11.9998 21.3443C18.1918 21.3443 21.7198 17.1063 23.2708 14.5803C23.7503 13.8048 24.0043 12.9111 24.0043 11.9993C24.0043 11.0875 23.7503 10.1938 23.2708 9.4183ZM21.5658 13.5333C20.2338 15.6993 17.2188 19.3443 11.9998 19.3443C6.78081 19.3443 3.76581 15.6993 2.43381 13.5333C2.14894 13.0723 1.99806 12.5412 1.99806 11.9993C1.99806 11.4574 2.14894 10.9263 2.43381 10.4653C3.76581 8.2993 6.78081 4.6543 11.9998 4.6543C17.2188 4.6543 20.2338 8.2953 21.5658 10.4653C21.8507 10.9263 22.0016 11.4574 22.0016 11.9993C22.0016 12.5412 21.8507 13.0723 21.5658 13.5333Z" fill="#D4D8E2"/>
            <path d="M11.9998 7C11.0109 7 10.0442 7.29324 9.22191 7.84265C8.39966 8.39206 7.7588 9.17295 7.38036 10.0866C7.00192 11.0002 6.90291 12.0055 7.09583 12.9755C7.28876 13.9454 7.76496 14.8363 8.46422 15.5355C9.16349 16.2348 10.0544 16.711 11.0243 16.9039C11.9942 17.0969 12.9995 16.9978 13.9132 16.6194C14.8268 16.241 15.6077 15.6001 16.1571 14.7779C16.7065 13.9556 16.9998 12.9889 16.9998 12C16.9982 10.6744 16.4709 9.40356 15.5335 8.46622C14.5962 7.52888 13.3254 7.00159 11.9998 7ZM11.9998 15C11.4064 15 10.8264 14.8241 10.333 14.4944C9.8397 14.1648 9.45518 13.6962 9.22812 13.1481C9.00106 12.5999 8.94165 11.9967 9.0574 11.4147C9.17316 10.8328 9.45888 10.2982 9.87844 9.87868C10.298 9.45912 10.8325 9.1734 11.4145 9.05764C11.9964 8.94189 12.5996 9.0013 13.1478 9.22836C13.696 9.45542 14.1645 9.83994 14.4942 10.3333C14.8238 10.8266 14.9998 11.4067 14.9998 12C14.9998 12.7957 14.6837 13.5587 14.1211 14.1213C13.5585 14.6839 12.7954 15 11.9998 15Z" fill="#D4D8E2"/>
        </g>
        <defs>
            <clipPath id="clip0_1786_5680">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>