import { usePrevious } from "Helpers/usePrevious";
import { useEffect } from "react";
import { useLocation } from "react-router";

type ScrollToTopProps = {
  children: React.ReactNode;
};

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    setTimeout(() => {
      if (prevLocation?.pathname !== location.pathname) {
        console.log("exec");

        window.scrollTo(0, 0);
      }
    });
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
