import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

type ButtonProps = {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  id?: string;
};

const Button: ReactFC<ButtonProps> = ({ children, onClick, className, id, disabled }) => {
  return (
    <button
      id={id}
      type="button"
      disabled={disabled}
      className={clsx(
        "w-full relative text-16 leading-[24px] text-center bg-theme-blue text-white rounded-[8px] h-[48px] focus:bg-theme-blue-press hover:bg-theme-blue-hover font-second-font font-semibold",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const Spinner: ReactFC<{ color?: string; classNames?: string }> = ({ color, classNames }) => {
  return (
    <svg
      className={clsx(`animate-spin -ml-1 mr-3 h-5 w-5 ${color || "text-primary-900"}`, classNames)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

type AsyncButtonProps = ButtonProps & {
  onClick: (() => unknown) | (() => Promise<unknown>);
};

export const AsyncButton: ReactFC<AsyncButtonProps> = ({ onClick, children, id, ...props }) => {
  const loadingRef = useRef(false);
  const mountedRef = useRef(false);
  const [_, setCount] = useState(0);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  const click = async () => {
    if (loadingRef.current) return;

    try {
      loadingRef.current = true;
      mountedRef.current && setCount((v) => v + 1);
      await onClick();
    } finally {
      loadingRef.current = false;
      mountedRef.current && setCount((v) => v + 1);
    }
  };

  return (
    <Button id={id} disabled={loadingRef.current} {...props} onClick={click}>
      {children}
      {loadingRef.current && (
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          <Spinner />
        </div>
      )}
    </Button>
  );
};
