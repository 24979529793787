import { PetaboxIcon } from "Images/Icons/PetaboxIcon";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";

type AuthBlockProps = {
  children: ReactElement;
};

export const AuthBlock = ({ children }: AuthBlockProps) => {
  return (
    <div className="h-full flex items-center min-h-screen bg-theme-blue smm:bg-white flex-col justify-between	">
      <div className="flex justify-center my-[60px] smm:my-[0] smm:mt-[30px]">
        <Link to={routesEnum.index}>
          <PetaboxIcon className="text-theme-blue sm:text-white h-[24px] mx-auto" fill="currentColor" />
        </Link>
      </div>

      <div className="max-w-[478px] w-full">{children}</div>
      <p className="smm:my-[0] smm:mb-[24px] my-[60px] text-16 leading-[24px] text-center max-w-[478px] w-full">
        <span className="text-white smm:text-theme-gray">Petabox © 2022. All Rights Reserved.</span>
      </p>
    </div>
  );
};
