import amazonS3MiniLogo from "../../Images/amazonS3MiniLogo.png";
import petaboxLogo from "../../Images/znak.svg";
import googleCloudMiniLogo from "../../Images/googleCloudMiniLogo.png";
import microsoftAzyreMiniLogo from "../../Images/microsoftAzyreMiniLogo.png";

export const data = (coldstack, azure, amazon, google) => [
  {
    cloud: "Petabox",
    value: coldstack,
    icon: petaboxLogo,
    columnSettings: { fill: "rgba(65,168,237,1)" },
    color: "#41A8ED",
  },
  {
    cloud: "Microsoft Azure",
    value: azure,
    icon: microsoftAzyreMiniLogo,
    columnSettings: { fill: "rgba(229,99,153,1)" },
    color: "#E56399",
  },
  {
    cloud: "Amazon S3",
    value: amazon,
    icon: amazonS3MiniLogo,
    columnSettings: { fill: "rgba(255,201,20,1)" },
    color: "#FF9900",
  },
  {
    cloud: "Google Cloud",
    value: google,
    icon: googleCloudMiniLogo,
    columnSettings: { fill: "rgba(145,222,169,1)" },
    color: "#91DEA9",
  },
];
