import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AuthBlock } from "Components/AuthBlock/AuthBlock";
import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { FormBlock } from "Components/Form/FormBlock";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";
import { resetPassword } from "Services/auth-service";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { AsyncButton } from "../../Components/Buttons/AsyncButton";
import { RoundCheck } from "../../Images/Icons/RoundCheck";

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("New password is a required field")
    .trim()
    .min(
      8,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    )
    .matches(
      /^(.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*){8,}$/,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Match password")
    .trim(),
});

type ResetPasswordType = {
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { control, trigger, handleSubmit, setError } = useForm<ResetPasswordType>({
    resolver: yupResolver(resetPasswordSchema),
  });

  const [confirm, setConfirm] = useState(false);

  const onSubmit = async (formData: ResetPasswordType) => {
    const valid = await trigger();
    if (!valid) return;

    const id = searchParams.get("id");
    const code = searchParams.get("code");

    if (id && code) {
      const { response } = await resetPassword({
        id,
        code,
        password: formData.password,
      });

      if (response && (response.status === 400 || response.status === 404)) {
        setError("password", { type: "custom", message: response.data.message });
        return;
      } else {
        setConfirm(true);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{!confirm && "Petabox: Reset Password"}</title>
        <meta name="description" content="Petabox: Reset Password" />
      </Helmet>

      <AuthBlock>
        <>
          {!confirm ? (
            <FormBlock title="Password recovery" submitHandler={handleSubmit(onSubmit)} submitText="Apply">
              <div className="grid grid-cols-1 gap-[24px] mb-[24px]">
                <ControlledInput type="password" control={control} name="password" required label="Password" />
                <ControlledInput
                  type="password"
                  control={control}
                  name="confirmPassword"
                  required
                  label="Confirm Password"
                />
              </div>
            </FormBlock>
          ) : (
            <div className="px-[40px] smm:px-[20px] py-[40px] bg-white rounded-[10px] flex flex-col justify-center text-center">
              <div className="flex justify-center mb-[24px]">
                <RoundCheck />
              </div>
              <h2 className="font-second-font font-semibold text-28 mb-[16px] ">Success</h2>
              <h3 className="text-12 mb-[24px] text-theme-gray">New password has been saved</h3>

              <AsyncButton
                id="signup"
                onClick={() => {
                  setConfirm(false);
                  navigate(routesEnum.signin);
                }}
              >
                Ok
              </AsyncButton>
            </div>
          )}
        </>
      </AuthBlock>
    </>
  );
};
