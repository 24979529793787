import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const formSchema = yup.object().shape({
  // redefine required error
  name: yup.string().required("some bla bla").trim(),
  /*
    change error label 
    @example without for required error .label 
    'lastName is a required field'
    @example within
    'Last Name is a required field'
  */
  lastName: yup.string().required().trim().label("Last Name"),
});

type Form = {
  name: string;
  lastName: string;
};

export const DevUiPage = () => {
  const { control, trigger } = useForm<Form>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async () => {
    const valid = await trigger();
    if (!valid) return;
  };

  return (
    <div>
      <ControlledInput type="text" control={control} name="name" />
      <button onClick={onSubmit}>sign up</button>
    </div>
  );
};
