import Select, { SingleValue } from "react-select";
import { RightIcons } from "Images/Icons/RightIcons";

export type OptionType = { label: string; value: string };

export type FormSelectorProps<T extends OptionType> = {
  onChange: (x: SingleValue<T>) => void;
  value: SingleValue<T>;
  options: SingleValue<T>[];
  label: string;
  placeholder: string;
  required: boolean;
  error?: string;
};

export function FormSelector<T extends OptionType>({
  label,
  placeholder,
  required,
  value,
  onChange,
  error,
  options,
}: FormSelectorProps<T>) {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 12,
      backgroundColor: "#ffffff",
      cursor: "pointer",
      fontSize: 16,
      color: "rgb(27, 42, 71)",
    }),
    control: (base, state) => ({
      ...base,
      height: 48,
      border: "0px solid #6e7e9d4d",
      boxShadow: state.isFocused ? "0 0 0 1px #41A8ED" : "0 0 0 1px #6e7e9d4d",
      padding: "5px 12px 5px 8px",
      borderRadius: "8px",
      display: "flex",
      width: "100%",
      fontSize: 16,
      color: "rgb(27, 42, 71)",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return { ...provided, opacity, color: "rgb(27, 42, 71)" };
    },
  };

  const formatOptionLabel = ({ label, value }) => <span data-test={value}>{label}</span>;

  return (
    <label data-test={label} className="block cursor-text">
      <div className="relative">
        <Select
          components={{ DropdownIndicator: () => <RightIcons />, IndicatorSeparator: () => null }}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          styles={customStyles}
          options={options}
          formatOptionLabel={formatOptionLabel}
        />
        {label && (
          <span className="text-[12px] leading-[2px] px-[4px] font-medium text-theme-gray absolute top-[-1px] left-[20px] translate-y-[-50%] bg-white">
            {label} {required && <span className="text-theme-error">&bull;</span>}
          </span>
        )}
        {error && (
          <p className="text-theme-error text-16 leading-[24px] mt-[10px]">
            {error.toString()[0].toUpperCase() + error.slice(1)}
          </p>
        )}
      </div>
    </label>
  );
}
