import { LocalStorage } from "Helpers/localStorage";
import { UserProfile } from "Models/UserModel";
import { atom, useSetRecoilState } from "recoil";

export const UserState = atom<UserProfile | null>({
  key: "UserState/atom",
  default: null,
});

export const useLogout = () => {
  const setUserState = useSetRecoilState(UserState);
  return () => {
    LocalStorage.deleteItem("accessToken");
    setUserState(null);
  };
};
