import clsx from "clsx";

type ButtonSocialProps = {
  onClick: () => void;
  className?: string;
  id?: string;
};

export const ButtonSocial: ReactFC<ButtonSocialProps> = ({ onClick, children, id, className }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={clsx(
        "flex h-[48px] hover:bg-gray-100 items-center justify-center border border-theme-light-grey2 rounded-[8px] gap-x-[19px] text-input-gray2 text-16 font-medium font-second-font",
        className
      )}
    >
      {children}
    </button>
  );
};
