import axios from "axios";

type ApiError = { code: number; message: string };

export const handleApiError = (error: unknown): ApiError => {
  if (axios.isAxiosError(error)) {
    const { statusCode, message } = error.response.data as { statusCode: number; message: string };
    return { code: statusCode, message };
  }

  return { code: 500, message: "Interal Error" };
};
