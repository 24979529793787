const { REACT_APP_ENV, REACT_APP_AUTHNODE_URL } = process.env;

const PROD_ENV = REACT_APP_ENV === "production";

type EnvValue<T extends string | boolean | number> = T;

const setupEnv = <T extends string | boolean>(defaultValue: T, envKey?: string): EnvValue<T> => {
  return (envKey as EnvValue<T>) || defaultValue;
};

let authApiUrl = "https://auth.petabox.io";

switch (true) {
  case REACT_APP_ENV === "development": {
    authApiUrl = setupEnv("https://auth.petabox.dev", REACT_APP_AUTHNODE_URL);
    break;
  }
  case REACT_APP_ENV === "local": {
    authApiUrl = "http://localhost:3000";
    break;
  }
  default: {
    authApiUrl = setupEnv("https://auth.petabox.io", REACT_APP_AUTHNODE_URL);
  }
}

export const config = {
  isProd: PROD_ENV,
  authApiUrl: authApiUrl,
} as const;
