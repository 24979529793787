import { Footer } from "Containers/Layout/Footer";
import { Header } from "Components/Header/Header";
import React from "react";
import {Helmet} from "react-helmet";

export const Terms = () => {
  return (
    <div
      className="
    h-full
    flex
    flex-col
    text-theme-black
    text-[1rem]
    bg-white
    mdm:block
    mdm:w-full
    "
    >
       <Helmet>
        <title>Petabox: Terms and Conditions of Service</title>
        <meta name="description" content="Petabox: Terms and Conditions of Service"/>
      </Helmet>
      <Header />
      <main
        className="
      grow
      shrink-0
      basis-auto
      "
      >
        <div
          className="
        w-full
        max-w-[1130px]
        pt-0
        px-[15px]
        pb-[60px]
        my-0
        mx-auto
        2xlm:max-w-[830px]
        mdm:pt-[40px]
        "
        >
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h1
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-32
            text-40
            "
            >
              Petabox™ Terms and Conditions of Service
            </h1>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              These Terms and Conditions of Service (the “Terms”) and our Privacy Policy govern your access to and use
              of the Petabox services and the Petabox website so please read them carefully.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>Effective date: Dec 10, 2020</b>
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              By using Petabox, you are agreeing to these Terms and the use of your Personal Information as described in
              our Privacy Policy. If you do not agree to these Terms, do not use Petabox. If you are using Petabox on
              behalf of an organization such as your employer, you are agreeing to these Terms on behalf of that
              organization, and represent and warrant that you have the authority to agree to these Terms on the
              organization's behalf. In that case, “you” and “your” will refer to that organization.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We may periodically revise the Terms. If a revision is material, as determined solely by us, we will
              notify you for example via email. The current version of our Terms will always be posted on our Terms
              page, so please check back regularly. By continuing to use Petabox after revisions become effective, you
              are agreeing to the revised Terms. If you do not agree to the revised Terms, please stop using Petabox.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              1. Your Petabox Account
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              To use Petabox, you may need to create an account.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You are responsible for safeguarding your Petabox login credentials. You are responsible for activity on
              your account, whether or not you authorized that activity. You should immediately notify us of any
              unauthorized use of your account.
            </p>
            <h4
              className="
            font-semibold
            text-18
            "
            >
              Service Account Information
            </h4>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We collect information such as your username, authentication tokens and other necessary security
              information required for authentication and access to our services.
            </p>
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              2. Private beta program
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              By signing up to Petabox private beta you understand that Petabox platform is a beta software which
              implies that the system may produce errors and issues which may result in the loss of data.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Petabox has the right to accept or reject participation in private beta programs at its sole discretion.
              The amount and number of rewards will be decided for each selected project separately.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Petabox reserves the right not to accept any submitted project to participate in a private beta program.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              3. Your Content
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              By using Petabox, you provide us with text, images, file attachments, and other information (“your
              content”). You retain full ownership of your content - what belongs to you stays yours.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You can remove your content by deleting it. However, in certain instances, some of your content may not be
              completely removed (when your data is shared with someone else, for example). We are not responsible or
              liable for the removal or deletion of any of your content, or the failure to remove or delete such
              content.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You are solely responsible for your content and indicate that you own or have the necessary rights to all
              of your content, and that use of your content does not infringe, misappropriate or violate a third party’s
              intellectual property rights, or rights of publicity or privacy, or result in the violation of any
              applicable law or regulation.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              4. Your Use of Petabox
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You may only use Petabox as permitted by law, including all applicable federal, state, local or
              international laws and regulations. Do not, for example:
            </p>
            <ul
              className="
            smm:text-16
            pl-[20px]
            leading-[1.6]
            "
            >
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Carry out your requests, fulfill orders, and process payments for our products and services;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Use any engine, software, tool, agent, device, mechanism or the like to access, search, or download
                intellectual property from Petabox, or use Petabox in any way other than through our publicly supported
                interfaces;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Access, tamper with, or use non-public areas of Petabox, Petabox’s computer systems, or the technical
                delivery systems of Petabox's providers;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Probe, scan, or test the vulnerability of any Petabox system or network or breach any security or
                authentication measures;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Decipher, decompile, disassemble or reverse engineer any of the software used to provide Petabox;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Plant malware or use Petabox to distribute malware;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Violate the privacy of others;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Violate any applicable law or regulation;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Impersonate or misrepresent your affiliation with any person or entity; or post or transmit anything
                that is fraudulent or misleading;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Send unsolicited communications, promotions, advertisements or spam or otherwise infringe on others'
                rights;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Interfere with the access of any user, host or network, including introducing any virus to, overloading,
                flooding, spamming, or mail-bombing Petabox, or introducing any other material or content which is
                malicious or technologically harmful;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Attack Petabox via a denial-of-service attack or a distributed denial-of-service attack; or otherwise
                attempt to interfere with the proper working of Petabox;
              </li>
              <li
                className="
               text-16
              mb-[1em]
              list-[square]
              "
              >
                Attempt any of the above, or encourage or enable any other individual to do any of the above.
              </li>
            </ul>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We have the right to investigate violations of these Terms and may also consult and cooperate with law
              enforcement authorities to prosecute users who violate the law.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              5. Confidentiality
            </h2>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              5.1 Confidential Information.
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              From time to time, either party (the "Disclosing Party") may disclose or make available to the other party
              (the "Receiving Party") non-public, proprietary, and confidential information of the Disclosing Party
              (“Confidential Information”). Confidential Information includes any information that reasonably should be
              understood to be confidential given the nature of the information and the circumstances of disclosure,
              including non-public business, product, technology and marketing information ("Confidential Information").
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Confidential Information does not include any information that: (a) is or becomes generally available to
              the public other than as a result of the Receiving Party's breach of this confidentiality section; (b) is
              or becomes available to the Receiving Party on a non-confidential basis from a third party source,
              provided that such third party is not and was not prohibited from disclosing such Confidential
              Information; (c) was in the Receiving Party's possession prior to the Disclosing Party's disclosure
              hereunder; or (d) was or is independently developed by the Receiving Party without using any of the
              Disclosing Party Confidential Information.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              5.2 Protection and Use of Confidential Information.
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              The Receiving Party shall: (a) protect and safeguard the confidentiality of the Disclosing Party's
              Confidential Information with at least the same degree of care as the Receiving Party would protect its
              own Confidential Information, but in no event with less than a commercially reasonable degree of care; (b)
              not use the Disclosing Party's Confidential Information, or permit it to be accessed or used, for any
              purpose other than to exercise its rights or perform its obligations under these Terms; and (c) not
              disclose any such Confidential Information to any person or entity, except to the Receiving Party's
              service providers or financial/legal advisors who need to know the Confidential Information and are bound
              to confidentiality obligations at least as restrictive as those in these Terms.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              5.3 Compelled Access or Disclosure.
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If the Receiving Party is required by applicable law or legal process to disclose any Confidential
              Information, it shall, prior to making such disclosure, use commercially reasonable efforts to notify the
              Disclosing Party of such requirements to afford the Disclosing Party the opportunity to seek, at the
              Disclosing Party's sole cost and expense, a protective order or other remedy.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              6. Copyright Infringement
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We respect the intellectual property rights of others, and expect you to do the same.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              It is our policy to terminate the Petabox account of anyone who repeatedly infringes the copyright or
              intellectual property rights of others.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If you believe that any materials you or others access via the Services infringe your copyright or other
              intellectual property rights (e.g., trademark infringement or right of publicity), please contact our
              Copyright Agent at the address below and provide the following information:
            </p>
            <ol>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Your full legal name and electronic or physical signature;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                A description of the copyrighted work or other interest that you believe has been infringed;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Enough information to properly identify and locate that content (including, at a minimum, the relevant
                URL);
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                Contact information, including your address, telephone number, and email address;
              </li>
              <li
                className="
              text-16
              mb-[1em]
              list-[square]
              "
              >
                The following statements in the body of notice:
                <ul
                  className="
                smm:text-16
                pl-[1em]
                leading-[1.6]
                "
                >
                  <li
                    className="
                  mb-[1em]
                  list-[square]
                  "
                  >
                    “I hereby state that I have not authorized the challenged use, and I have a good-faith belief that
                    the challenged use is not authorized by law.”
                  </li>
                  <li
                    className="
              mb-[1em]
              list-[square]
              "
                  >
                    “I hereby state under penalty of perjury that all of the information in the notification is accurate
                    and that I am the owner of the IP, or authorized to act on behalf of the owner of the IP.”
                  </li>
                </ul>
              </li>
            </ol>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Upon receipt of notice as described above, we will seek to confirm the existence of the IP on Petabox and
              take whatever action, in its sole discretion, we deem appropriate.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Our designated Copyright Agent for notices of copyright infringement and counter-notices is:
              info@petabox.io
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              7. Termination
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              We may terminate or modify your access to and use of Petabox, at our sole discretion, at any time and
              without notice to you, for example, if you are not complying with these Terms, or if you use Petabox in
              any way that would cause us legal liability or disrupt others’ use of Petabox.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Likewise, you may cancel your account at any time, although we will be sorry to see you go.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If we suspend or terminate your use of Petabox, we will try to let you know in advance and help you
              retrieve data, though there may be cases (for example, flagrantly violating these Terms) where we may
              suspend immediately.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              8. Publicity
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Unless otherwise specified, Petabox may use Customer’s name, logo and marks (including marks on Customer
              Properties) to identify Customer as a Petabox customer on Petabox's website and other marketing materials.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              9. Warranty Disclaimers
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              PETABOX IS PROVIDED “AS IS,” AT YOUR OWN RISK, WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY
              KIND. WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
              NON-INFRINGEMENT. Petabox will have no responsibility for any harm to your computer system, loss or
              corruption of data, or other harm that results from your access to or use of Petabox. Some states do not
              allow the types of disclaimers in this paragraph, so they may not apply to you.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              10. Indemnity
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You will hold harmless and indemnify Petabox and its affiliates, officers, directors, employees,
              contractors, agents, licensors, and suppliers from and against any claim, suit or action arising from or
              related to the use of Petabox or violation of these Terms, including any liability or expense arising from
              claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              11. Limitation of Liability
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (A) TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL PETABOX, ITS AFFILIATES, OFFICERS, EMPLOYEES,
              AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, PROFIT, DATA, GOOD WILL, SERVICE INTERRUPTIONS, COMPUTER
              DAMAGE OR SYSTEM FAILURE), REGARDLESS OF LEGAL THEORY, WHETHER OR NOT PETABOX D.O.O. HAS BEEN WARNED OF
              THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (B) AGGREGATE
              LIABILITY FOR ALL CLAIMS RELATING TO PETABOX SHALL BE NO MORE THAN THE GREATER OF $20 OR THE AMOUNTS PAID
              BY YOU TO PETABOX FOR THE PAST THREE MONTHS OF THE SERVICES IN QUESTION. Some states do not allow the
              types of limitations in this paragraph. If you are in one of these jurisdictions, these limitations may
              not apply to you.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]"
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              12. Terms of Paid Subscriptions
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If you purchase a subscription to the Services via the Petabox website (“Online Subscription”) or pursuant
              to any ordering document (“Order Form Subscription”), the following terms of subscription will apply to
              you:
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.1 Subscription Term
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              The Services are provided on a subscription basis for a term defined in the Online Subscription, or on the
              Order Form Subscription, as applicable (each, a “Subscription Term”).
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.2 Cancellation
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You may cancel your subscription at any time; however, you are responsible for advance payment of the
              entire Subscription Term. Fees are non-refundable based on the Subscription Term purchased and not actual
              usage. Payment obligations for the Subscription Term to which you subscribe are noncancelable.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.3 Renewals
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              For Online Subscriptions, each Subscription Term will automatically renew for an additional Subscription
              Term equal in length to the original Subscription Term. Online Subscriptions can be canceled directly at
              www.petabox.io
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              For Order Form Subscriptions, each Subscription Term will automatically renew for an additional
              Subscription Term equal in length to the original Subscription Term, unless cancellation is requested in
              writing (including by email to support@petabox.io) at least thirty (30) days prior to the expiration of
              the then-current Subscription Term.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.4 Billing and Payment
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If you purchase a subscription to the Services via credit card, debit card or other payment card
              (collectively, “Credit Card”), you hereby authorize Petabox (or its designee) to automatically charge your
              credit card in accordance with the applicable Online Subscription or Order Form Subscription. You
              acknowledge that certain credit cards may charge you foreign transaction fees or other charges. If your
              payment is not successfully settled for any reason, you remain responsible for any amounts not remitted to
              Petabox. Each net new user beyond the scope of the initial Online Subscription or Order Form Subscription,
              as applicable, will incur an additional charge, and will be included in a true-up invoice or charged
              automatically via credit card, as applicable.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.5 Late Payments
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Any late payments shall be subject to a service charge equal to 1.5% per month of the amount due or the
              maximum amount allowed by law, whichever is less (plus the costs of collection). Petabox may terminate
              your subscription if you fail to promptly pay any outstanding fees.
            </p>

            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.6 Taxes and Fees
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You are responsible for all sales, use, value added or other taxes of any kind, other than taxes based on
              Petabox’s net income. You are also responsible for any payment-related fees such as wire transfer or
              Credit Card processing fees.
            </p>
            <h3
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-20
            text-24
            "
            >
              12.7 Expenses
            </h3>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You are responsible for all fees or expenses related to accessing or using the Services that are extrinsic
              to the Services. This includes, without limitation, your own internet service provider fees.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
