import { Footer } from "Containers/Layout/Footer";
import { StartupForm } from "Components/Startup/StartupForm";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";
import {Helmet} from "react-helmet";

export const Startup = () => {
  const formRef = useRef(null);

  const scrollTo = () => {
    formRef.current.scrollIntoView();
  };
  return (
    <div
      className="
    h-full
    flex
    flex-col
    mdm:block
    mdm:w-full
    "
    >
       <Helmet>
        <title>Petabox: StartUp</title>
        <meta name="description" content="Petabox: StartUp"/>
      </Helmet>
      <main
        className="
      grow
      shrink-0
      basis-auto
      "
      >
        <div
          className="
        bg-contain
        bg-no-repeat
        bg-right-top
        smm:min-h-[400px]
        mdm:min-h-[unset]
        xlm:min-h-[600px]
        min-h-[700px]
        smm:max-h-[1000px]
        bg-[url('./Images/first-screen-bg.png')]
        mdm:bg-[url('./Images/first-screen-bg_tablet.png')]
        bg-theme-blue
        py-[100px]
        xlm:py-[80px]
        mdm:py-[70px]
        smm:py-[30px]
        smm:h-screen
        mdm:bg-auto
        flex
        justify-center
        items-center
        "
        >
          <div
            className="
          flex
          items-center
          2xlm:max-w-[1350px]
          max-w-[1470px]
          w-full
          px-[15px]
          mx-auto
          mdm:max-w-[680px]
          smm:justify-center
          mdm:flex-col-reverse
          mdm:min-h-[unset]
          xlm:min-h-[600px]
          z-10
          relative
          h-full"
          >
            <a
              href="#"
              className="
              absolute
              left-[15px]
              top-0
              h-[32px]
              inline-block
              mb-[6em]
              hidden
              mdm:block
              "
            >
              <img
                className="
                h-full
                max-w-full
                "
                src={require("../../Images/logo_white.svg").default}
                alt=""
              />
            </a>
            <div
              className="
            w-[55%]
            mdm:w-full
            mdm:relative
            "
            >
              <Link
                to={routesEnum.index}
                className="
              h-[32px]
              inline-block
              mb-[6em]
              mdm:hidden
               "
              >
                <img
                  className="
                h-full
                max-w-full
                block
                "
                  src={require("../../Images/logo_white.svg").default}
                  alt=""
                />
              </Link>
              <div
                className="
              lgm:w-full
              smm:mb-[30px]
              mb-[0.5em]
              text-white
              "
              >
                <img
                  src={require("../../Images/startup-logo.svg").default}
                  alt=""
                  className="
                lgm:max-w-full
                2xlm:max-w-[660px]
                "
                />
              </div>
              <div
                className="
              smm:mb-0
              my-[6rem]
              "
              >
                <div
                  className="
                mb-[1em]
                smm:text-16
                text-[3rem]
                text-white/60
                font-medium
                mdm:text-[3.2rem]
                "
                >
                  <span
                    className="
                text-white
                "
                  >
                    {" "}
                    Cloud Grant Program
                  </span>
                </div>
                <div
                  className="
                mb-[1em]
                smm:text-16
                text-[3rem]
                text-white/60
                font-medium
                mdm:text-[3.2rem]
                "
                >
                  Get{" "}
                  <span
                    className="
                white
                text-white
                "
                  >
                    10Tb of free
                  </span>{" "}
                  Cloud Storage Space for 12 months!
                </div>
              </div>
              <a
                onClick={scrollTo}
                className="
              smm:w-full
              mdm:min-w-[180px]
              h-[50px]
              inline-flex
              items-center
              justify-center
              text-16
              font-semibold
              h-[72px]
              min-w-[230px]
              rounded-[10px]
              outline-0
              border-0
              appearance-none
              duration-300
              bg-white
              text-theme-pink
              hover:text-white
              hover:bg-theme-pink
              active:bg-theme-pink2
              cursor-pointer
              "
              >
                Apply
              </a>
            </div>
          </div>
        </div>
        <div
          className="
        py-[160px]
        xlm:py-[140px]
        mdm:py-[100px]
        smm:py-[70px]
        "
        >
          <div
            className="
          mdm:max-w-[680px]
          2xlm:max-w-[1350px]
          max-w-[1470px]
          w-full
          py-0
          px-[15px]
          my-0
          mx-auto
          "
          >
            <div
              className="
            text-48
            2xlm:text-40
            mdm:text-32
            smm:text-24
            text-theme-black
            font-semibold
            text-center
            smm:mb-[1em]
            mdm:mb-[2em]
            mb-[1.6em]
            "
            >
              Сloud Storage for a Very Affordable Price
            </div>
            <div
              className="
            mdm:block
            flex
            justify-between
            "
            >
              <div
                className="
              w-[calc(50%-3.5rem)]
              mdm:w-full
              mdm:mb-[1.5rem]
              "
              >
                <p
                  className="
                text-20
                2xlm:text-18
                mdm:text-16
                smm:text-14
              "
                >
                  Petabox™ is reliable and easy-to-use Cloud Storage, which is 100% compatible with Amazon S3 API. It
                  uses AI-based storage optimization and effective data compression and decompression algorithms to
                  maintain cost-effective bulk storage for businesses of any size.
                </p>
                <p
                  className="
                text-20
                2xlm:text-18
                mdm:text-16
                smm:text-14
                "
                >
                  Petabox™ is Community Partner of{" "}
                  <a target="_blank" href="https://www.k-startupgc.org/member/agreement.do">
                    {" "}
                    K-startup Grand Challenge
                  </a>{" "}
                  and we are happy to supply any startup participating in this event in 2022 with a Cloud Grant:{" "}
                  <b>10Tb</b> of absolutely free storage space for 12 months.
                </p>
              </div>
              <div
                className="
              w-[calc(50%-3.5rem)]
              mdm:w-full
              "
              >
                <p
                  className="
                text-20
                2xlm:text-18
                mdm:text-16
                smm:text-14
              "
                >
                  Petabox™ is the perfect solution for:
                </p>
                <ul
                  className="
                smm:text-14
                smm:leading-[1.7]
                mdm:text-16
                text-[2rem]
                leading-[1.5]
                list-disc
                pl-[1em]
                "
                >
                  <li
                    className="
                  mb-[0.5em]
                  list-disc
                  "
                  >
                    {" "}
                    Data processing startups (video processing, tv&video programme backups, research agencies, etc)
                  </li>
                  <li
                    className="
                  mb-[0.5em]
                  list-disc
                  "
                  >
                    Medium-sized established businesses (import/export companies, data processing companies, data
                    hosters, etc)
                  </li>
                  <li
                    className="
                  mb-[0.5em]
                  list-disc
                  "
                  >
                    Large scale corporations which store large volumes of information to use in their Big Data chains.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="
        py-[160px]
        xlm:py-[140px]
        mdm:py-[100px]
        smm:py-[70px]
        bg-theme-light-grey
         "
        >
          <div
            className="
          mdm:max-w-[680px]
          2xlm:max-w-[1350px]
          max-w-[1470px]
          w-full
          py-0
          px-[15px]
          my-0
          mx-auto
          "
          >
            <div
              className="
            text-48
            2xlm:text-40
            mdm:text-32
            smm:text-24
            text-theme-black
            font-semibold
            text-center
            smm:mb-[1em]
            mdm:mb-[2em]
            mb-[1.6em]
            "
            >
              Grant Application Form
            </div>
            <div
              className="
            lgm:block
            flex
            justify-between"
            >
              <div
                className="
              grow
              pr-[10rem]
              lgm:px-[3rem]
              mdm:px-0
              "
              >
                <div
                  className="
                mb-[1.5em]
                smm:text-20
                mdm:text-24
                text-[3.2rem]
                leading-[1.3]
                font-medium
                "
                >
                  Petabox™ benefits:
                </div>
                <ul
                  className="
                custom-list
                mb-[24rem]
                lgm:mb-[130px]
                smm:mb-[56px]
                mdm:text-18
                "
                >
                  <li
                    className="
                  text-theme-dark-blue
                  mb-[1.8em]
                  smm:text-16
                  mdm:text-18
                  relative
                  text-[2.4rem]
                  leading-[1.5]
                  pl-[2em]
                  last:mb-0
                  "
                  >
                    10Tb of free Cloud storage space for 12 month:
                    <span
                      className="
                    text-theme-dark-blue
                    font-medium
                    "
                    >
                      {" "}
                      for all the participants of K-startup Grand Challenge 2022!
                    </span>
                  </li>
                  <li
                    className="
                  text-theme-dark-blue
                  mb-[1.8em]
                  smm:text-16
                  mdm:text-18
                  relative
                  text-[2.4rem]
                  leading-[1.5]
                  pl-[2em]
                  last:mb-0
                  "
                  >
                    Data-at-rest is encrypted with military grade security standards (AES-256/AES-512) with keys kept on
                    the client side only.{" "}
                    <span
                      className="
                    text-theme-dark-blue
                    font-medium
                    "
                    >
                      Only the customer owns his data and no one else.
                    </span>
                  </li>
                  <li
                    className="
                  text-theme-dark-blue
                  mb-[1.8em]
                  smm:text-16
                  mdm:text-18
                  relative
                  text-[2.4rem]
                  leading-[1.5]
                  pl-[2em]
                  last:mb-0
                  "
                  >
                    Stored data copies are made and stored in different geo locations.{" "}
                    <span
                      className="
                    black
                    text-theme-dark-blue
                    font-medium
                    "
                    >
                      To avoid hardware failures and geopolitical issues.
                    </span>
                  </li>
                  <li
                    className="
                  text-theme-dark-blue
                  mb-[1.8em]
                  smm:text-16
                  mdm:text-18
                  relative
                  text-[2.4rem]
                  leading-[1.5]
                  pl-[2em]
                  last:mb-0
                  "
                  >
                    PetaBox™ has 100% S3 protocol compatibility.
                    <span
                      className="
                    text-theme-dark-blue
                    font-medium
                    "
                    >
                      {" "}
                      Migration to Petabox™ is one click away!
                    </span>
                  </li>
                </ul>
                <div
                  className="
                smm:text-24
                mdm:text-[3.2rem]
                text-[3.6rem]
                leading-[1.3]
                font-semibold
                "
                >
                  The offer is available only
                  <br className="smm:hidden" /> for the official participants of
                  <br className="smm:hidden" /> K-startup Grand Challenge 2022
                </div>
                <div
                  className="
                mt-[7rem]
                flex
                items-center
                "
                >
                  <span
                    className="
                  w-[8rem]
                  h-[8rem]
                  mr-[2.4rem]
                  mdm:w-[56px]
                  mdm:h-[56px]
                  mdm:mr-[16px]
                  "
                  >
                    <img
                      className="
                    w-full
                    h-full
                    object-contain
                    "
                      src={require("../../Images/circle-logo.svg").default}
                      alt=""
                    />
                  </span>
                  <div className="company-logo-block__text">
                    <p
                      className="
                    text-20
                    text-theme-dark-blue
                    mdm:text-16
                    smm:text-14
                    "
                    >
                      Accelerate your startup in KOREA
                    </p>
                    <p
                      className="
                    text-20
                    text-theme-dark-blue
                    mdm:text-16
                    smm:text-14
                    font-medium
                    mb-0
                    "
                    >
                      <a target="_blank" href="https://www.k-startupgc.org/member/agreement.do">
                        How to join
                      </a>{" "}
                      K-startup GC 2022
                    </p>
                  </div>
                </div>
              </div>
              <div
                ref={formRef}
                className="
              lgm:w-full
              lgm:max-w-[535px]
              lgm:mt-[60px]
              lgm:mx-auto
              lgm:mb-0
              mdm:p-[20px]
              2xlm:w-[535px]
              flex-none
              w-[570px]
              bg-white
              shadow-[0px_16px_40px_rgba(0,35,102,0.1)]
              rounded-[10px]
              p-[4rem]
              "
              >
                <StartupForm />
                <div
                  className="
                smm:text-[12px]
                text-14
                leading-[1.7]
                text-[rgba(110, 126, 157, 0.5)]
                mt-[2em]
                text-center
                "
                >
                  By submitting this form, you are agreeing to be contacted by Petabox Sales and to receive our Business
                  Newsletter via email
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer backgroundColor="#fff" />
    </div>
  );
};
