import { Layout } from "Containers/Layout/Layout";
import React from "react";
import { Bullets } from "./partials/Bullets";
import { FirstScreenB2b } from "./partials/FirstScreenB2b";
import { Helmet } from "react-helmet";
import { HowItWork } from "./partials/HowItWork";
import { GetStarted } from "./partials/GetStarted";

export const B2b = () => {
  return (
    <Layout>
      <Helmet>
        <title>Petabox: S3 Compatible Object Storage</title>
        <meta name="description" content="Petabox: Data Storage Discounter" />
      </Helmet>
      <FirstScreenB2b />
      {/*<Bullets />*/}
      {/*<HowItWork />*/}
      {/*<GetStarted />*/}
    </Layout>
  );
};
