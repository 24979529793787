export const CiteFeldMan = () => {
  return (
    <div className="cite-testimonials">
      <div className="center">
        <blockquote className="cite-testimonials__blockquote">
          Petabox is great at having cheap reliable storage available through an s3 compatible endpoint.
          My favorite thing is their straightforward pricing.
          <cite className="cite-testimonials__cite">
            <div className="cite-testimonials__img">
              <img src="img/testimonials/people_testimonials.png" alt="Artur Norman, Integral Tech CEO"/>
            </div>
            <div className="cite-testimonials__text">
              <div className="cite-testimonials__name">Artur Norman</div>
              <div className="cite-testimonials__job">Integral Tech CEO</div>
            </div>
          </cite>
        </blockquote>
      </div>
    </div>
  );
};
