import {Footer} from "Containers/Layout/Footer";
import React from "react";
import {Header} from "Components/Header/Header";
import {Helmet} from "react-helmet";

export const Agreement = () => {
  return (
    <div
      className="
    h-full
    flex
    flex-col
    text-theme-black
    bg-white
    mdm:block
    mdm:w-full
    "
    >
      <Helmet>
        <title>Petabox: Customer Agreement</title>
        <meta name="description" content="Petabox: Customer Agreement"/>
      </Helmet>
      <Header/>
      <main
        className="
      grow
      shrink-0
      basis-auto
      "
      >
        <div
          className="
        w-full
        max-w-[1130px]
        pt-0
        px-[15px]
        pb-[60px]
        my-0
        mx-auto
        2xlm:max-w-[830px]
        mdm:pt-[40px]
        "
        >
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h1
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-32
            text-40
            "
            >
              Petabox™ Customer Agreement
            </h1>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Last Update: 01 March 2023
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              This Customer Agreement (this “Agreement”) contains the terms and conditions that govern your access to
              and use of the PETABOX data storage platform (the “PETABOX Service”) and is an agreement between you
              (“you” or “your”) and Lendeco Ltd. (“PETABOX,” “we,” “us,” or “our”). The acceptance by you of this
              Agreement, and any referenced policies. You may view and download various documents and policies at
              https://petabox.io prior to the registration process.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              CLICKING ON THE “ACCEPT” BUTTON OR CREATING A CUSTOMER ACCOUNT AND DOWNLOADING, INSTALLING AND/OR USING
              THE PETABOX SERVICE OR ANY TECHNOLOGY, IDEA, DATA, DATABASES, ALGORITHM OR INFORMATION CONTAINED THEREIN
              (EACH, AS APPLICABLE) OR PROVIDED THEREWITH, ESTABLISHES A BINDING AGREEMENT BETWEEN YOU AS THE PERSON
              USING THE SERVICES, EITHER ON BEHALF OF YOURSELF OR ANY THIRD PARTY ENTITY AND PETABOX, AND YOU AGREE TO
              BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT WITH RESPECT TO YOUR USE OF THE SERVICES.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL CAPACITY AND AUTHORITY TO ENTER INTO A BINDING AGREEMENT
              TO ADHERE TO THE TERMS AND CONDITIONS SET FORTH HEREIN, AND THAT THE PETABOX SERVICE WILL BE USED ONLY IN
              ACCORDANCE WITH THIS AGREEMENT AND WITH ALL APPLICABLE LAWS. IF AN INDIVIDUAL IS REGISTERING OR USING THE
              PETABOX SERVICE ON BEHALF OF AN ENTITY OR ORGANIZATION, THAT INDIVIDUAL WARRANTS, REPRESENTS, AND
              COVENANTS TO PETABOX THAT SUCH INDIVIDUAL IS DULY AUTHORIZED TO AGREE TO THIS AGREEMENT ON BEHALF OF THE
              ORGANIZATION AND TO BIND THE ORGANIZATION TO THEM.
            </p>
          </div>
          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              1. Use of the PETABOX Service
            </h2>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.1</b> Access. Subject to the terms and conditions of this Agreement, PETABOX hereby grants you and
              your Authorized Users (as defined below), for your internal business purposes and for so long as you
              maintain a Customer Account (as defined in Section 1.2) in good standing, a non-exclusive,
              non-transferable, non-sublicensable right to access and use the PETABOX Service (including any customer
              portals which may also be made available to you in as part of or through with the PETABOX Service) in
              accordance with the Documentation. “Documentation” means the user, technical and admin guides for the
              PETABOX Service located at https://petabox.io, as such Documentation may be updated by PETABOX from time
              to time.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.2</b> The nature of the PETABOX Services is that the Customer may use its Customer Account to store
              its Customer Content in the <b>S3 compatible cloud data storage, in which the defragmented parts of the
              Customer Content shall be distributed among the given decentralized technical infrastructure.</b> Neither
              PETABOX nor Contract Users shall have access to the Customer Content.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.3</b> Customer Accounts.

              (a) A customer account (a “Customer Account”) shall be created in order to allow you to use the PETABOX
              Service. We will use your account information in accordance with our Privacy Policy (found at
              https://petabox.io), and you consent to such usage, where “account information” means information about
              you that you provide to us in connection with the creation or administration of your Customer Account. For
              example, account information includes names, usernames, phone numbers, email addresses and billing
              information associated with your Customer Account.
              (b) Unless explicitly permitted by this Agreement, only one Customer Account is created per each email
              address. You agree to provide accurate and complete information in the creation of your Customer Account,
              and you acknowledge and agree you will update this information with any changes. You may authorize others
              (collectively, “Authorized Users”) to use the PETABOX Service on your behalf. Each Authorized User will
              establish or be provided with a username and password. You are responsible for the acts and omissions of
              your Authorized Users. Authorized Users may also be required to use other access credentials, such as an
              encryption key (collectively, “User Credentials”). We reserve the right to suspend or terminate the
              Customer Account, or the access of any Authorized User, for any reason, including if any registration
              information is inaccurate, untrue or incomplete, or if you or any of your Authorized Users fail to
              maintain the security of any User Credentials.
              (c) You and your Authorized Users are responsible for ensuring that User Credentials are kept confidential
              and are not disclosed to any third party. You are fully responsible for all activity that occurs under
              your Customer Account using the User Credentials, including any loss or deletion of Your Content (as
              defined in Section 1.7 below), regardless of whether the activities are authorized by you or undertaken by
              you, your employees or a third party (including your contractors, agents or End Users).
              (d) You agree to, and will ensure that each Authorized User will, notify us at support@PETABOX.com
              immediately upon learning of any unauthorized access to a Customer Account or any other suspected security
              breach.

            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b>1.4</b> Usage Data. To provide billing and administration services, or to investigate fraud, abuse or
              violations of this Agreement, we may process usage data related to your Customer Account, such as, by way
              of example only, resource identifiers, metadata tags, security and access roles, rules, usage policies,
              permissions, usage statistics and analytics (“Usage Data”).
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.5 Limited PETABOX Services.</b>  We may offer the PETABOX Service for free in limited circumstances, such as
              free trials, or may offer a pre-release version of any PETABOX Service (“Limited PETABOX Service”). The
              Limited PETABOX Service requires an active Customer Account and is subject to the time limits that are
              made known to you at the time of order fulfillment, via email or the Documentation. You acknowledge and
              agree that you will use the Limited PETABOX Service in compliance with any related Documentation and
              restrictions. You acknowledge that your, and your Authorized User’s, use of any Limited PETABOX Service is
              for the sole purpose of evaluating and testing the applicable such service and providing feedback to
              PETABOX. Further, you agree that any pre-release version of a PETABOX service may be experimental in
              nature, have not been fully tested and may be discontinued at any time with or without notice. We may, in
              our discretion, discontinue any Limited PETABOX Service at any time, at which point your access will end
              and any Your Content shall be deleted.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.6 Client Download.</b> The PETABOX Service may require some software to be installed by you. Subject to the
              terms and conditions of this Agreement, and in consideration of the applicable fees paid by you, PETABOX
              shall grant to you a non-exclusive, nontransferable, limited license (without the right to sublicense) to
              install, execute and use such software solely: (i) in object code format; (ii) for your internal use, with
              no right to make such software available to third parties either by transferring copies thereof or by
              providing a hosted service; and (iii) in accordance with Documentation and the terms hereof.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>1.7 Your Content.</b> “Your Content” means content that you or any Authorized User transfer to us for storage
              or hosting by the PETABOX Service in connection with your Customer Account. Your content does not include
              your Customer Account information. You will ensure that Your Content will not violate any terms of this
              Agreement or referenced PETABOX policies or Documentation, or any applicable law. You are solely
              responsible for the development, content, operation, maintenance, and use of Your Content.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              You represent and warrant to us that: (a) you or your licensors own all right, title, and interest in and
              to Your Content and Feedback; (b) you have all rights in Your Content and Feedback necessary to grant the
              rights contemplated by this Agreement; and (c) none of Your Content use of the PETABOX Service will
              violate the Platform Terms of Use and Acceptable Use Policy.

            </p>

          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              2. PETABOX Changes; Support and Maintenance
            </h2>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>2.1 To the PETABOX Service.</b> We may change or discontinue any or all of the PETABOX Service or change or
              remove functionality of any or all of the PETABOX Service from time to time. We will notify you of any
              material change to or discontinuation of the PETABOX Service by posting the revised version on our website
              and/or communicating it to you. Any changes to the PETABOX Services will be effective ten (10) days from
              the time we post such changes. Your continued use of the PETABOX Services thereafter constitutes your
              acceptance the changed PETABOX Service.
            </p>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >


              <b>2.2 To the APIs.</b> We may change or discontinue any APIs for the PETABOX Service from time to time. For any
              discontinuation of or material change to an API, we will use commercially reasonable efforts to continue
              supporting the previous version of such API for 12 months after the change or discontinuation (except if
              doing so (a) would pose a security or intellectual property issue, (b) is economically or technically
              burdensome, or (c) would cause us to violate the law or requests of governmental entities).
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>2.3 To the Service Level Agreements.</b> We may change, discontinue or add Service Level Agreements from time
              to time.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>2.4 Support and Maintenance.</b> During the term of the Usage Term, PETABOX will provide you with reasonable
              support via the PETABOX ticketing system, in accordance with PETABOX’s then-current service level
              agreement. PETABOX and its Affiliates will provide you with service updates and bug fixes that we in its
              sole discretion makes generally available to its other similarly users of the PETABOX Service at no
              charge. PETABOX will use commercially reasonable efforts to correct reproducible failures of the PETABOX
              Service to perform in substantial accordance with its Documentation. Premium support may be purchased from
              or through PETABOX for an additional fee. Further, PETABOX itself and through its Affiliates will use
              reasonable efforts to schedule maintenance to the PETABOX Service during non-peak usage hours. PETABOX may
              temporarily limit or suspend the availability of all or part of the PETABOX Service, without liability, if
              it is necessary for reasons of public safety, security, maintenance of the PETABOX Service,
              interoperability of services, data protection, or to perform work that is necessary for operational or
              technical reasons.
            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              3. Security and Data Privacy
            </h2>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              <b>3.1 PETABOX Security.</b> (a) With account taken of Section 1.2. above, without limiting your obligations
              under Section 4.2 or the terms of Section 10, we will implement reasonable and appropriate measures
              designed to help you secure Your Content against accidental or unlawful loss, access or disclosure. (b)
              However, we cannot guarantee that unauthorized third parties will never be able to defeat our safeguards
              or use the Customer Account and/or Your Content for improper purposes. You provide your Customer Account
              details and Your Content to us at your own risk, and you are responsible for properly configuring and
              using the PETABOX Service and otherwise taking appropriate action to secure, protect and backup your
              Customer Account(s) and Your Content in a manner that will provide appropriate security and protection,
              which might include use of encryption to protect Your Content from unauthorized access and routinely
              archiving Your Content.

            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              4. License Grant; Acknowledgment
            </h2>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >


              <b>4.1 License of Your Content.</b> You grant to PETABOX a worldwide, non-exclusive, royalty-free, fully-paid up,
              transferable and sublicensable right to use and store Your Content for the purpose of performing this
              Agreement, improving the PETABOX Service, and as otherwise provided in PETABOX’s privacy policy, as may be
              updated by us from time to time. You retain all rights Your Content, subject to the rights granted to us
              in this Agreement with respect to the performance of our obligations. You may modify or remove the Your
              Content via the Customer Account. Your Content will be irretrievably deleted upon (i) you or your
              Authorized User’s deletion of Your Content or (ii) cancellation or termination of the Customer Account.

            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              <b>4.2 Restrictions.</b> You acknowledge and agree that you shall not, and shall ensure your Authorized Users do
              not: (i) access or use the PETABOX Service in any manner or for any purpose other than expressly permitted
              by the Documentation; (ii) change, modify or otherwise create derivative works of all or any portion of
              the PETABOX Service; (iii) modify, disassemble, decompile or reverse engineer any part of the PETABOX
              Service or apply any other process or procedure to derive source code of any software included in PETABOX
              Service (except solely to the extent permitted by applicable law); (iv) access or use the PETABOX Service
              in a way intended to avoid exceeding usage limits or quotas; (v) use PETABOX Service in order to build a
              similar or competitive application or service; or (vi) remove, tamper with or alter any disabling
              mechanism or circumvent any technical protection measures associated with the PETABOX Service, or
              otherwise use any tool to enable features or functionalities that are otherwise disabled in PETABOX
              Service.

            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              You further acknowledge and agree that, unless otherwise expressly agreed or otherwise permitted by
              PETABOX, in writing (x) resell or sublicense the PETABOX Service; or (y) remove or alter any proprietary
              notices (e.g., copyright and trademark notices) pertaining to the PETABOX Service.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>4.3 Log-In Credentials and Account Keys.</b> Any log-in credentials and private keys provided to you by
              PETABOX or generated by the PETABOX Service are for your internal use only and you will not sell, transfer
              or sublicense them to any other entity or person, except that you may disclose your private key to your
              agents and subcontractors performing work on your behalf.

            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>4.4 Authorized Users.</b> You will be deemed to have taken any action that you permit, assist or facilitate
              any person or entity to take related to this Agreement, Your Content or use of the PETABOX Service. You
              are responsible for your Authorized Users’ use of Your Content and the PETABOX Service. You will ensure
              that all Authorized Users comply with your obligations under this Agreement. If you become aware of any
              violation of your obligations under this Agreement caused by an Authorized User, you will immediately
              suspend access to Your Content and the PETABOX Service by such Authorized User. We do not provide any
              support or services to any Authorized User unless we have a separate agreement obligating us to provide
              such support or services to an Authorized User.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>4.5 Assessments.</b> You acknowledge and agree that (i) you assessed your data protection needs, network
              environment, technical specifications, and fees and cost of the PETABOX Service, and you are solely
              responsible for your decision to use the PETABOX Service even if PETABOX has provided guidance to you in
              connection with the PETABOX Service selected by you; and (ii) you are solely responsible for ensuring that
              you maintain and operate your information technology infrastructure in a manner appropriate to your use of
              the PETABOX Service and its Documentation.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>4.6 Your Conduct.</b> You represent and warrant that Your Content and you and your Authorized User’s, use of
              the PETABOX Service, and the technology related thereto, shall not (i) interfere with the proper working
              of the PETABOX Services or impose an unreasonably large load on the PETABOX infrastructure; (ii) give rise
              to civil or criminal liability, e.g. defamatory, threatening, pornographic, indecent, abusive, libelous or
              otherwise objectionable actions; (iii) violate or infringe upon any third party right, including any
              intellectual property right or right of privacy, or that abuses, harasses or stalks any other person; or
              (iv) initiate a denial of service attack, software viruses or other harmful or deleterious computer code,
              files or programs such as Trojan horses, worms, time bombs, cancelbots, or spyware.
            </p>

          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              5. Fees and Payment
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>5.1 Service Fees.</b> Fees for the PETABOX Service (“Fees”) will calculated and billed based the Order Form.
              Generally, our Fees are payable on pay-as-you-go basis and we issue invoices on monthly basis as to the
              Services provided in the previous calendar month.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If we suspect that your account is at risk of non-payment, as determined in our discretion, we may bill
              you more frequently.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              All amounts payable by you under this Agreement will be paid to us without any setoff or counterclaim. We
              may increase or add new fees and charges for any existing PETABOX Service by giving you at least thirty
              (30) days’ prior notice. We may elect to charge you interest at the rate of 1.5% per month (or the highest
              rate permitted by law, if less) on all late payments.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>5.2 Payments.</b> We issue invoices on monthly basis based on the Service you have used; these invoices are
              payable within 10 calendar days upon issuance.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Alternatively, you may pay for the Services using credit cards or other methods, depending on availability
              on our side. We utilize third party payment providers to process payments on PETABOX’s behalf (“Payment
              Provider”). Such Payment Provider’s policies govern the processing of your payment, and you must refer to
              those policies and not this Agreement to determine your rights and liabilities. By providing your credit
              card information to the Payment Provider, you authorize PETABOX, through such Payment Provider, to
              immediately charge the Fees for the PETABOX Service during any applicable term for PETABOX Service.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Your failure to maintain your current credit card information or other alternative payment method may
              result in an interruption of your use of the PETABOX Service.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b> 5.3 Taxes.</b> All fees payable by you are exclusive of means any taxes and duties, including, without
              limitation, VAT, Service Tax, GST, excise taxes, sales and transactions taxes, and gross receipts tax
              (“Taxes”), which are your responsibility. We will charge and you will pay such applicable Taxes. We will
              not collect, and you will not pay, any Taxes for which you furnish us a properly completed exemption
              certificate or a direct payment permit certificate for which we may claim an available exemption. You
              shall indemnify, defend and hold PETABOX harmless for any liability or expense we may incur in connection
              with the failure to pay, or collection of, any Taxes.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>5.4 No Deduction.</b> All payments made by you to us under this Agreement will be made free and clear of any
              deduction or withholding. If any such deduction or withholding (including but not limited to cross-border
              withholding taxes) is required on any payment, you will pay such additional amounts as are necessary so
              that the net amount received by us is equal to the amount then due and payable under this Agreement.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b> 5.5 Ongoing Fees.</b> You acknowledge and agree that your credit card or alternative payment method will be
              automatically charged usage Fees during such period as you have an active Customer Account and you use the
              PETABOX Service unless you cancel your Customer Account. You may cancel your Customer Account by
              contacting us.
            </p>


          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              6. Proprietary Rights; Confidentiality
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>6.1 Reservation of Rights.</b> You acknowledge and agree that the software, code, hardware, trademarks, trade
              secrets, proprietary methods and systems used to provide the PETABOX Service (the “PETABOX Technology”)
              and the content made available or displayed by us through the PETABOX Service, including all text,
              graphics, images and the look and feel of such PETABOX Service (collectively, the “PETABOX Content”) are
              owned by or licensed to PETABOX, including all intellectual property rights therein. Nothing in this
              Agreement or any of the Documentation shall be considered an assignment or other transfer of ownership in
              and to the PETABOX Technology or PETABOX Content to you, either expressly, or by implication, estoppel, or
              otherwise. Other than the authorizations or licenses as may be conferred or granted by us to you in this
              Agreement or any of the Documentation, PETABOX reserves all right, title and interest in and to the
              PETABOX Technology and PETABOX Content. No right or license is granted by us to you or your Authorized
              Users to use any PETABOX trademark, trade name, service mark, product name or other source designator.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Subject to the terms of this Agreement, you are hereby granted a limited, revocable, non-exclusive,
              non-sublicensable, non-transferrable license to do the following: (a) access and use the PETABOX Service
              solely in accordance with this Agreement; and (b) copy and use the PETABOX Content solely in connection
              with your permitted use of the PETABOX Service. Except as provided in this Section 6.1, you obtain no
              rights under this Agreement from us, our Affiliates or our licensors to the PETABOX Service, including any
              related intellectual property rights. Some PETABOX Content and Third-Party Content may be provided to you
              under a separate license, such as the Apache License, Version 2.0, or other open source license. In the
              event of a conflict between this Agreement and any separate license, the separate license will prevail
              with respect to the PETABOX Content or Third-Party Content that is the subject of such separate license.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>6.2 Ongoing Development.</b> PETABOX may develop and provide ongoing innovation to the PETABOX Service,
              including new features, functionality, and efficiencies. In the event PETABOX adds new features or
              functionality (collectively, “New Features”) to the PETABOX Service, PETABOX may offer the New Features to
              you at no additional charge or, if PETABOX generally charges customers for such functionality, PETABOX may
              condition your use of the New Features on the payment of additional Fees.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>6.3 Feedback.</b> Notwithstanding anything to the contrary in this Agreement, you hereby agree that all
              intellectual property rights in the Feedback, and all other ownership in any ideas, modifications,
              enhancements, improvements, or any other suggestion specifically relating to the PETABOX Service, are
              hereby assigned to PETABOX and shall be the sole and exclusive property of PETABOX. All Feedback shall be
              treated as PETABOX’s Confidential Information. Without limiting the generality of the foregoing, you agree
              that your provision of Feedback does not give you any intellectual property or any other right, title, or
              interest in or to any aspects of the PETABOX Service, even if such Feedback leads to the creation of a new
              product or service by PETABOX, or New Features. “Feedback” means any suggestions, enhancement requests,
              recommendations, corrections or other feedback provided by you, your affiliates, and/or Authorized Users
              relating to the features or operation of the PETABOX Service and Documentation.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>6.4 Confidentiality.</b> Either party may, from time to time, deliver to the other certain non-public
              information including formulas, flow charts, diagnostic routines, business information, forecasts,
              financial plans and data, balance sheet information, customer information, marketing plans, hardware,
              software and unannounced product information (“Confidential Information”). Confidential Information shall
              also include the PETABOX Content and Your Content, and any other information disclosed by a party to the
              other party, in whatever form, including visually and orally, and designated in writing as proprietary or
              confidential, or which – to a reasonable person familiar with the disclosing party’s business and the
              industry in which it operates – is of a proprietary or confidential nature. During the term of this
              Agreement and following three (3) years after its termination, each party will not disclose any such
              Confidential Information except as set forth herein. The receiving party shall hold in confidence, and
              shall not disclose (or permit or suffer its personnel to disclose) any Confidential Information to any
              person or entity except to a director, officer, employee, outside consultant, or advisor (collectively
              “Representatives”) who have a need to know such Confidential Information in the course of the performance
              of their duties for the receiving party and who are bound by a duty of confidentiality no less protective
              of the disclosing party’s Confidential Information than this Agreement. The receiving party and its
              Representatives shall use such Confidential Information only for the purpose for which it was disclosed
              and shall not use or exploit such Confidential Information for the benefit of another without the prior
              written consent of the disclosing party. Each party accepts responsibility for the actions of its
              Representatives and shall protect the other party’s Confidential Information in the same manner as it
              protects its own valuable confidential information, but in no event, shall less than reasonable care be
              used. The parties expressly agree that the terms of this Agreement are Confidential Information and you
              further agree that it shall not use the PETABOX Service for the purposes of conducting comparative
              analysis, evaluations or product benchmarks with respect to the services and will not publicly post any
              analysis or reviews of the services without PETABOX’s prior written approval. A receiving party shall
              promptly notify the disclosing Party upon becoming aware of a breach or threatened breach hereunder and
              shall cooperate with any reasonable request of the disclosing party in enforcing its rights
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Information will not be deemed Confidential Information hereunder if such information: (i) is known prior
              to receipt from the disclosing party, without any obligation of confidentiality; (ii) becomes known to the
              receiving party directly or indirectly from a source other than one having an obligation of
              confidentiality to the disclosing party; (iii) becomes publicly known or otherwise publicly available,
              except through a breach of this Agreement; or (iv) is independently developed by the receiving party
              without use of the disclosing party’s Confidential Information. The receiving party may disclose
              Confidential Information pursuant to the requirements of applicable law, legal process or government
              regulation, provided that, where legally permitted to do so, it gives the disclosing party reasonable
              prior written notice to permit the disclosing party to contest such disclosure, and such disclosure is
              otherwise limited to the required disclosure.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Notwithstanding any other provision of this Agreement, both parties acknowledge that any use of the
              disclosing party’s Confidential Information in a manner inconsistent with the provisions of this Agreement
              may cause the disclosing party irreparable and immediate damage for which remedies other than injunctive
              relief may be inadequate. Therefore, both parties agree that, in addition to any other remedy which the
              disclosing party may be entitled hereunder, at law or equity, the disclosing party shall be entitled to an
              injunction or injunctions (without the posting of any bond and without proof of actual damages) to
              restrain such use in addition to other appropriate remedies available under applicable law.

            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              7. Term; Termination; Suspension
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              <b>7.1 Term.</b> Subject to the provisions of Section 7.2, the term of your access to the PETABOX Service (a
              “Usage Period”) will continue for so long as you have an active and fully paid up Customer Account (the
              “Term”).
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>7.2 Termination.</b>
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (a) Termination For Convenience. Subject to Section 5, either party may terminate this Agreement and your
              Customer Account for any reason by providing the other party at least thirty (30) days’ advance notice.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (b) Termination for Failure to Pay. In the event you fail to make any payment as set forth in this
              Agreement, we reserve the right to disable your Customer Account after twenty-four (24) hours’ notice to
              you. You are required to make payment of all outstanding amounts before your Customer Account is
              re-enabled.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              If you fail to respond to the first notice of payment failure, we will provide a second final notice that
              if outstanding amounts are not paid in full within twenty-four (24) hours our delivery of the second
              notice, we reserve the right to delete your Customer Account and Your Content within seventy-two (72)
              hours from such final notice.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>(c) Termination for Cause.</b>
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (i) By Either Party. EEither party may terminate this Agreement and your Customer Account for cause if the
              other party is in material breach of this Agreement and/or your Customer Account and the material breach
              remains uncured for a period of thirty (30) days from receipt of notice by the other party.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (ii) By PETABOX. We may also terminate this Agreement and/or your Customer Account immediately upon notice
              to you (A) if our relationship with Affiliates and/or a third-party partner who provides software or other
              technology we use to provide the PETABOX Service expires, terminates or requires us to change the way we
              provide the software or other technology as part of the PETABOX Service, or (B) in order to comply with
              law.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>7.3 Effect of Termination.</b> Upon the Termination Date: (i) all your rights under this Agreement and your
              Customer Account immediately terminate; (ii) you remain responsible for all fees and charges you have
              incurred through the termination date and are responsible for any fees and charges you incur up to
              termination; (iii) you will immediately return or, if instructed by us, destroy all PETABOX content in
              your possession; and (iv) those terms intended to survive termination shall continue to apply in
              accordance with their terms.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>7.4 Immediate Termination or Suspension.</b> We may elect to terminate or suspend (in our sole discretion)
              your or any Authorized User’s right to access or use any portion or all of the PETABOX Service immediately
              upon notice to you if we determine:
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (a) your or an End User’s use of the PETABOX Service (i) poses a security risk to PETABOX, the PETABOX
              Service, any other PETABOX customer, or any third party, (ii) could adversely impact our systems, the
              PETABOX Service or the systems or content of any other PETABOX customer, (iii) could subject us, our
              Affiliates, or any third party to civil or criminal liability, or (iv) could be criminal, fraudulent or
              otherwise illegal;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (b) you are, or any Authorized User is, in material breach of this Agreement;
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (c) you are in breach of your payment obligations under Section 5; or
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (d) you have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or
              similar disposition of your assets, or become the subject of any bankruptcy, reorganization, liquidation,
              dissolution or similar proceeding.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>7.5 Effect of Suspension.</b> If we suspend your right to access or use any portion or all of the PETABOX
              Service, (a) you remain responsible for all fees and charges you incur during the period of suspension;
              and (b) you will not be entitled to any service credits under the Service Level Agreements for any period
              of suspension.

            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              8. Indemnification
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>8.1 In General By You.</b> You will defend, indemnify, and hold harmless us, our Affiliates and licensors, and
              each of their respective employees, officers, directors, and representatives from and against any claims,
              damages, losses, liabilities, costs, and expenses (including reasonable attorneys’ fees) (“Losses”)
              arising out of or relating to any third-party claim concerning: (a) your or any Authorized Users’ use of
              the PETABOX Service (including any activities under your Customer Account and use by your Authorized
              Users); (b) violation of applicable law, rule or regulation by you, your Authorized Users or Your Content;
              or (c) any claim alleging that any of Your Content infringes or misappropriates any privacy or third party
              intellectual property rights. You will reimburse us for reasonable attorneys’ fees, as well as our
              employees’ and contractors’ time and materials spent responding to any third-party subpoena or other
              compulsory legal order or process associated with third party claims described in (a) and (b) above at our
              then-current hourly rates.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b>8.2 PETABOX Indemnity Obligations.</b>
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (a) Subject to the limitations in this Section 8, PETABOX will defend you and your employees, officers,
              and directors against any third-party claim alleging that the PETABOX Service infringes or misappropriates
              a third party’s intellectual property rights, and will pay the amount of any adverse final judgment or
              settlement, agreed to by PETABOX in writing. PETABOX will also defend, indemnify, and hold you and your
              employees, officers, and directors from and against and Losses arising out of PETABOX’s violation of any
              applicable law, rule or regulation.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (b) We will have no obligation or liability under this Section 8.2 arising from infringement by
              combinations of the PETABOX Service with any other product, service, software, data, content or method. In
              addition, PETABOX will have no obligations or liability arising from your or any Authorized User’s use of
              the PETABOX Service after PETABOX has notified you to discontinue such use. The remedies provided in this
              Section 8.2 are the sole and exclusive remedies for any third-party claims of infringement or
              misappropriation of intellectual property rights by the PETABOX Services.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (c) For any claim covered by Section 8.2(a), PETABOX will, at its election, either: (i) procure the rights
              to use that portion of the PETABOX Service alleged to be infringing; (ii) replace the alleged infringing
              portion of the PETABOX Service with a non-infringing alternative; (iii) modify the alleged infringing
              portion of the PETABOX Service to make it non-infringing; or (iv) terminate the allegedly infringing
              portion of the PETABOX Services and this Agreement.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>8.3 Process.</b> The obligations under this Section 8 will apply only if the indemnified party: (a) gives the
              other party prompt written notice of the claim; (b) permits the other party to control the defense and
              settlement of the claim; and (c) reasonably cooperates with the other party (at the other party’s expense)
              in the defense and settlement of the claim. In no event will a party agree to any settlement of any claim
              that involves any commitment, other than the payment of money, without the written consent of the other
              party.


            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              9. Disclaimers
            </h2>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              THE PETABOX SERVICE IS PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT OF ANY
              STATUTORY RIGHTS THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS (A) MAKE
              NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING
              THE PETABOX SERVICE OR THE THIRD-PARTY CONTENT, AND (B) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
              STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED OR EXPRESS WARRANTIES (I) OF
              MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET
              ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE, (III) THAT THE PETABOX SERVICE OR
              ANY THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL COMPONENTS, AND (IV) THAT ANY
              OF YOUR CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR ALTERED.


            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              10. Limitations of Liability
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              EXCEPT IN THE CASE OF PETABOX’S GROSS NEGLIGENCE, WILLFUL MISCONDUCT OR FRAUD, PETABOX AND ITS AFFILIATES
              AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES, CUSTOMERS, OPPORTUNITIES, GOODWILL,
              USE, OR DATA), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE FORM
              OF ACTION.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              EXCEPT IN THE CASE OF PETABOX’S GROSS NEGLIGENCE, WILLFUL MISCONDUCT OR FRAUD, PETABOX AND ITS AFFILIATES’
              AND LICENSORS’ AGGREGATE LIABILITY UNDER THIS AGREEMENT, REGARDLESS OF THE FORM OF ACTION, WILL NOT EXCEED
              THE AMOUNT YOU ACTUALLY PAY PETABOX UNDER THIS AGREEMENT FOR THE PETABOX SERVICE THAT GAVE RISE TO THE
              CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE. IF PETABOX SERVICE (OR ANY OTHER PETABOX PRODUCT)
              IS PROVIDED TO YOU WITHOUT CHARGE, THEN PETABOX WILL HAVE NO LIABILITY TO YOU WHATSOEVER, AND IN ANY EVENT
              NO MORE THAN $500.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              YOU EXPRESSLY RECOGNIZE AND ACKNOWLEDGE THAT THE DISCLAIMERS AND LIMITATIONS SET FORTH IN THIS SECTION ARE
              AN ESSENTIAL PART OF THE AGREEMENT AND AN ESSENTIAL FACTOR IN ESTABLISHING THE PRICE OF PETABOX SERVICE.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              THE FOREGOING DISCLAIMERS AND LIMITATIONS WILL APPLY TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW
              AND NOTWITHSTANDING A FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY PROVIDED IN THIS AGREEMENT.

            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              11. Modifications to the Agreement
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              We may modify this Agreement (including any policies or any links referenced herein) at any time by
              posting a revised version on the PETABOX website or by otherwise notifying you in accordance with Section
              12.10. Subject to the foregoing, the modified terms will become effective upon posting or, if we notify
              you by email, as stated in the email message. By continuing to use the PETABOX Service after the effective
              date of any modifications to this Agreement, you agree to be bound by the modified terms. It is your
              responsibility to check the PETABOX website regularly for modifications to this Agreement.


            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              12. Miscellaneous
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.1 Assignment.</b> You will not and do not have the right to assign or otherwise transfer this Agreement or
              any of the rights and obligations under this Agreement, without PETABOX’s prior written consent. Any
              assignment or transfer, or attempt thereof, in violation of this Section 12.1 will be void.
              Notwithstanding the foregoing, either party may assign this Agreement to any person or entity that is an
              affiliate, or acquires by sale, merger or otherwise, all or substantially all or a portion of such party’s
              assets, stock or business. If another entity merges with or acquires PETABOX, or all, substantially all or
              a portion of our assets, stock or business you agree your encrypted stored data and information that
              PETABOX has collected from you, including personally identifiable information, may, and you consent to,
              the secure transfer of such information to such successor or assignee.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b>12.2 Affiliates, Subcontractors and Vendors.</b> Some or all of the PETABOX Service, including support
              services, may be provided by PETABOX’s Affiliates, agents, subcontractors and information system vendors.
              The rights and obligations of PETABOX may be, in whole or in part, exercised or fulfilled by the foregoing
              entities.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.3 Entire Agreement.</b> This Agreement incorporates any policies or other information found at the
              published links by reference and is the entire agreement between you and us regarding the subject matter
              of this Agreement. This Agreement supersedes all prior or contemporaneous representations, understandings,
              agreements, or communications between you and us, whether written or verbal, regarding the subject matter
              of this Agreement. We will not be bound by, and specifically object to, any term, condition or other
              provision that is different from or in addition to the provisions of this Agreement (whether or not it
              would materially alter this Agreement) including for example, any term, condition or other provision (a)
              submitted by you in any order, receipt, acceptance, confirmation, correspondence or other document, (b)
              related to any online registration, response to any Request for Bid, Request for Proposal, Request for
              Information, or other questionnaire, or (c) related to any invoicing process that you submit or require us
              to complete. If the terms of this Agreement are inconsistent with the terms contained in document
              referenced or linked herein, the terms contained in such document will control.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.4 Force Majeure.</b> We and our Affiliates will not be liable for any delay or failure to perform any
              obligation under this Agreement where the delay or failure results from any cause beyond our reasonable
              control, including acts of God, labor disputes or other industrial disturbances, electrical or power
              outages, utilities or other telecommunications failures, earthquake, storms or other elements of nature,
              epidemics, pandemics, blockages, embargoes, riots, acts or orders of government, acts of terrorism, or
              war.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.5 Governing Law.</b> The laws of the Republic of Cyprus, without reference to conflict of law rules, shall
              govern this Agreement and any dispute of any sort that might arise between the parties.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.6 Trade Compliance.</b> In connection with this Agreement, each party will comply with all applicable
              import, re-import, sanctions, anti-boycott, export, and re-export control laws and regulations. For
              clarity, you are solely responsible for compliance related to the manner in which you choose to use the
              PETABOX Service, including your transfer and processing of Your Content, the provision of Your Content to
              Authorized Users, and the PETABOX region in which any of the foregoing occur. You represent and warrant
              that you and your financial institutions, or any party that owns or controls you or your financial
              institutions, are not subject to sanctions or otherwise designated on any list of prohibited or restricted
              parties, including but not limited to the lists maintained by the United Nations Security Council, the
              U.S. Government (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the
              U.S. Department of Treasury, and the Entity List of the U.S. Department of Commerce), the European Union
              or its Member States, or other applicable government authority.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.7 Independent Contractors; Non-Exclusive Rights.</b> Both parties are independent contractors, and this
              Agreement will not be construed to create a partnership, joint venture, agency, or employment
              relationship. Neither party, nor any of their respective Affiliates is an agent of the other for any
              purpose or has the authority to bind the other. Both parties reserve the right (a) to develop or have
              developed for it products, services, concepts, systems, or techniques that are similar to or compete with
              the products, services, concepts, systems, or techniques developed or contemplated by the other party, and
              (b) to assist third party developers or systems integrators who may offer products or services which
              compete with the other party’s products or services.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.8 Language.</b> All communications and notices made or given pursuant to this Agreement must be in the
              English language. If we provide a translation of the English language version of this Agreement, the
              English language version of the Agreement will control if there is any conflict.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.9 Confidentiality and Publicity.</b> You may use PETABOX Confidential Information only in connection with
              your use of the PETABOX Service as permitted under this Agreement. You will not disclose PETABOX
              Confidential Information during the Term or at any time during the 5-year period following the end of the
              Term. You will take all reasonable measures to avoid disclosure, dissemination or unauthorized use of
              PETABOX Confidential Information, including, at a minimum, those measures you take to protect your own
              confidential information of a similar nature. You will not issue any press release or make any other
              public communication with respect to this Agreement or your use of the PETABOX Service.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.10 Notice.</b>
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (a) To You. We may provide any notice to you under this Agreement by: (i) posting a notice on the PETABOX
              website; or (ii) sending a message to the email address then associated with your account. You agree that
              all disclosures, notices and communications are considered received by you within twenty-four (24) hours
              of the time posted to PETABOX’s website, or within twenty-four (24) hours of the time emailed to you. It
              is your responsibility to keep your email address current. You will be deemed to have received any email
              sent to the email address then associated with your account when we send the email, whether or not you
              actually receive the email.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              (b) To Us. To give us notice under this Agreement, you must contact PETABOX as follows: (i) by email
              transmission to{" "}
               <a
                className="
              text-theme-blue
              font-bold
              underline
              smm:inline-block
              smm:overflow-hidden
              smm:text-ellipsis
              smm:max-w-full
              hover:no-underline
              "
                target="_blank"
                href="mailto:info@petabox.io"
              >

                info@petabox.io
              </a>. ; or (ii) by personal delivery, overnight courier or registered or
              certified mail to either Lendeco Ltd., the attention of General Counsel and CFO. Notices provided by
              personal delivery will be effective immediately. Notices provided by email transmission or overnight
              courier will be effective one (1) business day after they are sent. Notices provided registered or
              certified mail will be effective three (3) business days after they are sent.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
             <b> 12.12 No Third-Party Beneficiaries.</b> This Agreement does not create any third-party beneficiary rights in
              any individual or entity that is not a party to this Agreement.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.13 No Waivers.</b> The failure by either party to enforce any provision of this Agreement will not
              constitute a present or future waiver of such provision nor limit a party’s right to enforce such
              provision at a later time. All waivers by a party must be in writing to be effective.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.14 Severability.</b> If any portion of this Agreement is held to be invalid or unenforceable, the remaining
              portions of this Agreement will remain in full force and effect. Any invalid or unenforceable portions
              will be interpreted to effect and intent of the original portion. If such construction is not possible,
              the invalid or unenforceable portion will be severed from this Agreement, but the rest of the Agreement
              will remain in full force and effect.
            </p>
            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              <b>12.15 Marketing.</b> Each party may use the other party’s name, trade name, trademarks, icons, and logos
              (collectively, the “Brands”) to refer publicly to the other, orally and in writing, as a customer/vendor
              of the other solely in connection with the PETABOX Service and only during the Term. Any other use of a
              party’s Brands requires such party’s prior written consent.

            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              13. Export Controls and Sanctions.
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              Shall your use of the PETABOX Service may be subject to compliance with United States and other applicable
              export control and trade sanctions laws, rules and regulations (collectively, “Sanctions and Export
              Control Laws”), you will not export, reexport, download or otherwise transmit the PETABOX Service, or
              technical data relating thereto, in violation of any applicable Sanctions and Export Control Laws. In that
              case, in particular, you acknowledge that the PETABOX Services, or any part thereof, may not be exported,
              transmitted, or re-exported to, or otherwise used in: (a) any country subject to a U.S. embargo or
              comprehensive trade sanctions or that has been designated a state sponsor of terrorism by the U.S.
              Government (“Sanctioned Countries”); or (b) anyone identified on any U.S. Government restricted party
              lists (collectively, “Restricted Party Lists”). By purchasing the PETABOX Service, you represent and
              warrant that you are not located in any Sanctioned Country or on any Restricted Party List. You
              acknowledge that the PETABOX Service may not be available in all jurisdictions and that you are solely
              responsible for complying with applicable Export Control Laws related to the manner in which you choose to
              use the PETABOX Service, including your transfer and processing of your Your Content and the region in
              which any of the foregoing occur.
            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              14. Electronic Communications.
            </h2>


            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >
              By using PETABOX Service, you acknowledge that we communicate with you electronically to the email address
              provided in your Customer Account. It is your responsibility to keep your email address current for notice
              purposes. For contractual purposes, you (a) consent to receive communications from PETABOX in an
              electronic form and (b) agree that all terms and conditions, agreements, notices, disclosures, and other
              communications that PETABOX provides to you electronically satisfies any legal requirement that such
              communications would satisfy if it were to be in writing.
            </p>
          </div>

          <div
            className="
          mb-[70px]
          smm:mb-[40px]
          "
          >
            <h2
              className="
            smm:mb-[24px]
            mb-[40px]
            font-semibold
            smm:text-24
            text-32
            "
            >
              15. Contact Us.
            </h2>

            <p
              className="
            leading-[1.6]
            smm:text-16
            smm:mb-[20px]
            text-18
            mb-[30px]
            "
            >

              To contact PETABOX regarding this Agreement email PETABOX at

  {" "}
               <a
                className="
              text-theme-blue
              font-bold
              underline
              smm:inline-block
              smm:overflow-hidden
              smm:text-ellipsis
              smm:max-w-full
              hover:no-underline
              "
                target="_blank"
                href="mailto:info@petabox.io"
              >

                info@petabox.io
              </a>.

            </p>
          </div>


        </div>
      </main>
      <Footer/>
    </div>
  );
};
