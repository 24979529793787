import { SubPlanEnum } from "common/consts";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { routesEnum } from "Routes/routesEnum";
import { SelectedPlanState } from "store/store";

export const Pricing = () => {
  const setPlan = useSetRecoilState(SelectedPlanState);

  return (
    <div className="pricing" id="pricing">
      <div className="center">
        <h2 className="title h2">Simple no-tricks pricing. 30-day free trial</h2>

        <div className="pricing__box">
          <div className="pricing__item">
            <h3 className="pricing__head h3">Pay As You Go</h3>

            <div className="pricing__price">
              <p className="pricing__price-old">$3.99 per TB</p>

              <p className="pricing__price-current">
                $3.99
                <small className="pricing__time">/&nbsp;&nbsp;Month</small>
              </p>
            </div>

            <ul className="pricing__list">
              <li>1 TB Storage / 1 TB Transfer</li>

              <li>$0.003 / GB Additional Storage</li>

              <li>$0.001 / GB Additional Transfer</li>

              <li>No commitment</li>

              <li>Priority email support</li>
            </ul>

            <div className="pricing__btn">
              <Link
                onClick={() => setPlan(SubPlanEnum["Pay As You Go"])}
                to={routesEnum.signup}
                className="btn btn--big btn--blue"
                id="front_plan_1"
              >
                Try for free
              </Link>
            </div>
          </div>

          <div className="pricing__item is-popular">
            <p className="pricing__label">
              <span className="pricing__label-inner">Most Popular</span>
            </p>

            <h3 className="pricing__head h3">Professional</h3>

            <div className="pricing__price">
              <p className="pricing__price-old">$2.99 per TB</p>

              <p className="pricing__price-current">
                $29.90
                <small className="pricing__time">/&nbsp;&nbsp;Month</small>
              </p>
            </div>

            <ul className="pricing__list">
              <li>10 TB Storage / 10 TB Transfer</li>

              <li>$0.003 / GB Additional Storage</li>

              <li>$0.001 / GB Additional Transfer</li>

              <li>Priority email support</li>

              <li>Live chat support</li>
            </ul>

            <div className="pricing__btn">
              <Link
                onClick={() => setPlan(SubPlanEnum.Professional)}
                to={routesEnum.signup}
                id="front_plan_2"
                className="btn btn--big btn--white"
              >
                Try for free
              </Link>
            </div>
          </div>

          <div className="pricing__item">
            <h3 className="pricing__head h3">Corporate</h3>

            <div className="pricing__price">
              <p className="pricing__price-old">$2.99 per TB</p>

              <p className="pricing__price-current">
                $299.90
                <small className="pricing__time">/&nbsp;&nbsp;Month</small>
              </p>
            </div>

            <ul className="pricing__list">
              <li>100 TB Storage / 100 TB Transfer</li>

              <li>$0.003 / GB Additional Storage</li>

              <li>$0.001 / GB Additional Transfer</li>

              <li>
                Free data migration <sup>NEW</sup>
              </li>

              <li>Priority email support</li>

              <li>Live chat support</li>
            </ul>

            <div className="pricing__btn">
              <Link
                onClick={() => setPlan(SubPlanEnum.Corporate)}
                id="front_plan_3"
                to={routesEnum.b2b}
                className="btn btn--big btn--blue"
              >
                Try for free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
