import { yupResolver } from "@hookform/resolvers/yup";
import { AuthBlock } from "Components/AuthBlock/AuthBlock";
import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { FormBlock } from "Components/Form/FormBlock";
import { signUpFormSchema } from "Containers/Signup/signUpFormSchema";
import { SignUpModel } from "Models/SignUp";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routesEnum } from "Routes/routesEnum";
import { register } from "Services/auth-service";
import { SelectedPlanState } from "store/store";
import { Helmet } from "react-helmet";
import { changeLocation, sendMetricEvent } from "Helpers/common";
import { useShowApiError } from "hooks/useShowApiError";
import { handleApiError } from "Helpers/handeApiError";
import { getSearchParams } from "Helpers/searchParams";

export const SignUp = () => {
  const { control, trigger, getValues, setError } = useForm<SignUpModel & { confirmPassword: string }>({
    resolver: yupResolver(signUpFormSchema),
    reValidateMode: "onChange",
  });
  const tariffId = useRecoilValue(SelectedPlanState);
  const [apiError, setApiError] = useShowApiError();
  const location = useLocation();
  const params = getSearchParams(location.search);

  useEffect(() => {
    const error = params?.error;

    if (error) {
      setApiError(error);
    }
  }, [params]);

  const onSubmit = async () => {
    const valid = await trigger();
    setApiError("");
    if (!valid) return;
    const formData = getValues();
    const { confirmPassword, ...data } = formData;
    try {
      const response = await register({ ...data, tariffId });
      if (response.status === 409) {
        setError("email", { type: "custom", message: response.data.message });
        return;
      } else if (response.user) {
        sendMetricEvent("Form - reg_page");
        changeLocation(`${window.origin}/dashboard`);
      }
    } catch (e) {
      const err = handleApiError(e);
      setApiError(err.message);
    }
  };

  useEffect(() => {
    try {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "Page - sing_up",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Petabox: Sign Up</title>
        <meta name="description" content="Petabox: Sign Up" />
      </Helmet>
      <AuthBlock>
        <FormBlock
          title="Create an account"
          subTitle={
            <div>
              <span className="text-[#1B2A47] text-[16px] font-semibold source-sans-pro">Have an account? </span>
              <Link
                className="text-theme-blue text-[16px] font-source-sans-pro font-semibold hover:underline"
                to={routesEnum.signin}
              >
                Sign in
              </Link>
            </div>
          }
          submitText="Sign Up"
          submitHandler={onSubmit}
          showSocial
        >
          <div className="grid grid-cols-1 gap-[24px] mb-[24px]">
            <ControlledInput type="text" control={control} name="name" required label="Name" />
            {/*<ControlledInput type="text" control={control} name="companyName" label="Company"/>*/}
            {/*<ControlledInput type="text" control={control} name="website" label="Website"/>*/}
            <ControlledInput type="email" control={control} name="email" required label="Email" />
            <ControlledInput type="password" control={control} name="password" required label="Password" />
            <ControlledInput
              type="password"
              control={control}
              name="confirmPassword"
              required
              label="Confirm Password"
            />
            {apiError && <small className="text-red-500">{apiError}</small>}
            <p className="text-[12px] text-[#6E7E9D] leading-[24px]">
              I accept the{" "}
              <Link className="text-theme-blue underline hover:no-underline" to={routesEnum.termsOfService}>
                Terms of use
              </Link>{" "}
              and give my consent to Petabox to process my personal data for the services outlined in the{" "}
              <Link className="text-theme-blue underline hover:no-underline" to={routesEnum.privacyPolicy}>
                Privacy Policy
              </Link>
            </p>
          </div>
        </FormBlock>
      </AuthBlock>
    </div>
  );
};
