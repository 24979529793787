import { useMemo } from "react";

export const StorageNeeds = () => {
  const items = useMemo(
    () => [
      {
        key: "File Backup",
        value:
          "Storing backups is the leading use case of our Storage. All the leading backup tools can be integrated with Petabox in just a few clicks.",
      },
      {
        key: "Secondary Backup",
        value:
          "Already have a backup on another cloud? However, there’s a chance that it might fail, which is why having a second one is a good idea.",
      },
      {
        key: "File Sharing",
        value:
          "You can use Petabox to share your files publicly and privately over the internet. Access management offers file-level granularity.",
      },
      {
        key: "Large File Hosting",
        value:
          "Petabox allows you to store the large files: up to 2 TB each! This includes videos, photos and audio files as well as database dump files.",
      },
      {
        key: "Cold Data Storage",
        value:
          "Petabox is an ideal storage for the “cold” data, that is rarely used or accessed, but must be stored for a very long time or even forever.",
      },
      {
        key: "Logs",
        value:
          "With Petabox cloud storage you will never have to worry about logs rotation or losing any of your log files after deleting your instance.",
      },
    ],
    []
  );

  return (
    <div className="storage-needs">
      <div className="center">
        <h2 className="title h2">Cloud storage for all your needs</h2>

        <dl className="storage-needs__list">
          {items.map((x, idx) => (
            <div key={idx} className="storage-needs__item">
              <dt className="h3">{x.key}</dt>
              <dd>{x.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};
