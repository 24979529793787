import { useAnchor } from "Helpers/useAnchor";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routesEnum } from "Routes/routesEnum";
import { useLogout, UserState } from "store/UserState";

type HeaderMenuItem = {
  href: string;
  label: string;
  showLogined?: boolean;
  hash?: boolean;
  changeLocation?: boolean;
};

export const Header = () => {
  const scrollTo = useAnchor();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const userProfile = useRecoilValue(UserState);
  const logout = useLogout();

  const items: HeaderMenuItem[] = useMemo(
    () =>
      [
        { href: "#pricing", label: "Pricing", hash: true },
        { href: "#calculator", label: "Calculator", hash: true },
        { href: "#faq", label: "FAQ", hash: true },
        { href: "/dashboard/", label: "Dashboard", showLogined: true, changeLocation: true },
      ].filter((x) => {
        const checkLogginedState = x.showLogined === true;
        if (checkLogginedState) {
          return !!userProfile;
        }
        return true;
      }),
    [userProfile]
  );

  const closeHamburger = () => {
    document.body.classList.remove("menu-show");
    document.documentElement.classList.remove("scroll-behavior-off");
  };
  const handleClick = (href: string, sroll = true, changeLocation = false) => {
    return () => {
      const id = href.substring(1, href.length);

      if (hamburgerOpen) {
        closeHamburger();
        setHamburgerOpen(false);
      }
      if (changeLocation) {
        window.location.href = `${window.location.origin}/dashboard`;
      }
      sroll && scrollTo(id);
    };
  };
  const haburgerClick = () => {
    setHamburgerOpen((state) => {
      const nextState = !state;

      if (nextState) {
        document.documentElement.classList.add("scroll-behavior-off");
        document.body.classList.add("menu-show");
      } else closeHamburger();

      return nextState;
    });
  };

  useEffect(() => {
    closeHamburger();
  }, []);

  return (
    <header className="header">
      <div className="center">
        <div className="header__flex">
          <div className="header__logo">
            <svg width="216" height="40" viewBox="0 0 216 40">
              <g id="svg-logo">
                <path d="M57.7 40V9.33h5.23v2.68a8.3 8.3 0 0 1 6.53-3.11c5.97 0 10.45 4.56 10.45 11.09 0 6.52-4.48 11.09-10.45 11.09a8.47 8.47 0 0 1-6.53-3.12V40l-5.22.01ZM74.7 20c0-3.84-2.53-6.4-5.88-6.4-3.36 0-5.88 2.56-5.88 6.4 0 3.84 2.52 6.4 5.88 6.4 3.35 0 5.88-2.56 5.88-6.4ZM87.23 21.7c.56 3.12 2.65 4.9 5.87 4.9 2.31 0 3.66-.85 4.36-2.33h5.66c-1.3 4.43-5.1 6.82-10.24 6.82-6.23 0-10.8-4.56-10.8-11.09s4.57-11.09 10.8-11.09 10.8 4.56 10.8 11.1c0 .59-.05 1.14-.1 1.7H87.23Zm.04-3.62H98.5c-.56-2.99-2.57-4.69-5.61-4.69-3.05 0-5.06 1.7-5.62 4.69ZM117.18 26.4c.81 0 1.62-.14 2.39-.39v4.57c-1.12.33-2.27.5-3.44.51-4.31 0-7.45-1.67-7.45-7.38v-9.9h-3.48V9.33h3.49V3.47h5.22v5.86h5.66v4.48h-5.66v9.56c0 2.1 1 3.03 3.27 3.03ZM122.4 24.95c0-9.17 14.15-5.08 14.15-9.34 0-1.67-1.74-2.65-4-2.65-2.4 0-4.06 1.24-4.06 3.63h-5.43c0-5.55 4.6-7.68 9.48-7.68 4.79 0 9.22 1.92 9.22 6.9v14.85h-5.12v-3.15c-1.39 2.51-3.92 3.58-6.92 3.58-3.75 0-7.32-1.83-7.32-6.14Zm8.4 1.86c3.05 0 5.84-1.7 5.84-4.95v-.98c-3.48 1.4-8.88.98-8.88 3.71 0 1.51 1.31 2.22 3.04 2.22ZM168.77 20c0 6.53-4.49 11.09-10.45 11.09a8.49 8.49 0 0 1-6.53-3.11v2.67h-5.23V0h5.23v12.02a8.3 8.3 0 0 1 6.53-3.11c5.96 0 10.45 4.56 10.45 11.09Zm-16.98 0c0 3.84 2.52 6.4 5.87 6.4s5.9-2.56 5.9-6.4c0-3.84-2.53-6.4-5.88-6.4s-5.9 2.56-5.9 6.4ZM170.94 20c0-6.53 4.75-11.1 11.1-11.1 6.36 0 11.1 4.57 11.1 11.1 0 6.53-4.74 11.09-11.1 11.09-6.35 0-11.1-4.56-11.1-11.1Zm16.98 0c0-3.84-2.52-6.4-5.88-6.4-3.35 0-5.87 2.56-5.87 6.4 0 3.84 2.52 6.4 5.88 6.4 3.35 0 5.87-2.56 5.87-6.4ZM193.36 30.66l8.23-11-7.8-10.33h6.1l5 6.62 4.58-6.62h6.1l-7.8 10.46L216 30.66h-6.1l-5.48-7.25-4.96 7.25h-6.1ZM13.25 26.57h18.44v5.71H12.74a12.97 12.97 0 0 1-9.01-3.65A12.47 12.47 0 0 1 0 19.8h6.34a6.65 6.65 0 0 0 2.02 4.79 6.92 6.92 0 0 0 4.89 1.98Z" />
                <path d="M38 26.58h-6.32a6.7 6.7 0 0 0-1.83-4.6 6.96 6.96 0 0 0-4.53-2.15 6.14 6.14 0 0 0-1.95-4.2 6.4 6.4 0 0 0-8.73-.01 6.14 6.14 0 0 0-1.96 4.19H6.34a12.29 12.29 0 0 1 3.09-7.45A12.91 12.91 0 0 1 24.66 9.4a12.54 12.54 0 0 1 5.76 5.72c2.26 1 4.18 2.6 5.52 4.64a12.37 12.37 0 0 1 2.07 6.83v-.01ZM35.92" />
              </g>
            </svg>
          </div>

          <ul className="header__menu">
            {items.map((x) => (
              <li key={x.href}>
                <Link
                  onClick={handleClick(x.href, x.hash, x.changeLocation)}
                  to={{ pathname: x.hash ? "" : x.href, hash: x.hash ? x.href : "" }}
                >
                  {x.label}
                </Link>
              </li>
            ))}

            <li>
              <a href="https://docs.petabox.io/" target="_blank" rel="noopener nofollow">
                Docs
              </a>
            </li>

            <div className="header__user header__user_mob">
              {userProfile ? (
                <button
                  onClick={() => {
                    logout();
                    closeHamburger();
                  }}
                  className="btn btn--outline"
                >
                  Logout
                </button>
              ) : (
                <>
                  <Link onClick={closeHamburger} to={routesEnum.signup} className="btn btn--link">
                    Sign Up
                  </Link>
                  <Link onClick={closeHamburger} to={routesEnum.signin} className="btn btn--outline">
                    Sign In
                  </Link>
                </>
              )}
            </div>
          </ul>

          <div className="header__user">
            {userProfile ? (
              <button onClick={logout} className="btn btn--outline">
                Logout
              </button>
            ) : (
              <>
                <Link id="sign-up" to={routesEnum.signup} className="btn btn--link">
                  Sign Up
                </Link>
                <Link id="sign-in" to={routesEnum.signin} className="btn btn--outline">
                  Sign In
                </Link>
              </>
            )}
          </div>

          <div onClick={haburgerClick} className="header__btn-menu">
            <button className="button btn-menu" type="button" aria-label="toggle menu">
              <span className="btn-menu__top"></span>
              <span className="btn-menu__center"></span>
              <span className="btn-menu__bottom"></span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
