import React from "react";

export const PetaboxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="166" height="56" viewBox="0 0 301 56" xmlns="http://www.w3.org/2000/svg">
    <path d="M80.417 56V13.0679H87.6973V16.8149C88.7816 15.4587 90.1639 14.3618 91.7404 13.6066C93.3169 12.8514 95.0467 12.4574 96.8 12.4543C105.108 12.4543 111.361 18.8431 111.361 27.9812C111.361 37.1194 105.108 43.5082 96.8 43.5082C95.0467 43.5051 93.3169 43.1111 91.7404 42.3559C90.1639 41.6006 88.7816 40.5038 87.6973 39.1475V55.9813L80.417 56ZM104.08 28C104.08 22.6229 100.559 19.0398 95.8864 19.0398C91.2137 19.0398 87.6973 22.6229 87.6973 28C87.6973 33.377 91.2137 36.9555 95.8864 36.9555C100.559 36.9555 104.085 33.3724 104.085 28H104.08Z" />
    <path d="M121.553 30.3886C122.342 34.7493 125.255 37.2551 129.742 37.2551C132.958 37.2551 134.838 36.0607 135.809 33.9764H143.703C141.88 40.1872 136.603 43.5315 129.428 43.5315C120.748 43.5315 114.377 37.1427 114.377 28.0045C114.377 18.8663 120.748 12.4776 129.428 12.4776C138.107 12.4776 144.474 18.8663 144.474 28.0045C144.474 28.8382 144.412 29.6158 144.35 30.3933L121.553 30.3886ZM121.614 25.3113H137.265C136.48 21.1333 133.686 18.7539 129.442 18.7539C125.197 18.7539 122.4 21.1427 121.614 25.3113Z" />
    <path d="M163.292 36.9556C164.424 36.9513 165.548 36.7711 166.623 36.4216V42.8104C165.068 43.2723 163.455 43.5136 161.831 43.527C155.821 43.527 151.453 41.1851 151.453 33.1945V19.3396H146.604V13.068H151.458V4.8572H158.738V13.068H166.628V19.3396H158.738V32.7167C158.738 35.6629 160.137 36.9556 163.292 36.9556Z" />
    <path d="M170.568 34.9272C170.568 22.0888 190.287 27.8218 190.287 21.8499C190.287 19.508 187.86 18.145 184.705 18.145C181.375 18.145 179.062 19.878 179.062 23.2223H171.482C171.482 15.4564 177.911 12.4728 184.705 12.4728C191.367 12.4728 197.553 15.1614 197.553 22.145V42.9272H190.416V38.5103C188.474 42.0326 184.953 43.5267 180.766 43.5267C175.546 43.5267 170.568 40.96 170.568 34.9272ZM182.279 37.5361C186.528 37.5361 190.411 35.1473 190.411 30.6087V29.2363C185.557 31.2035 178.039 30.6087 178.039 34.4307C178.034 36.5384 179.866 37.5361 182.279 37.5361Z" />
    <path d="M235.182 28C235.182 37.1382 228.929 43.527 220.621 43.527C218.868 43.5232 217.139 43.1289 215.562 42.3737C213.986 41.6185 212.603 40.522 211.519 39.1663V42.9134H204.238V0H211.519V16.8337C212.603 15.478 213.986 14.3815 215.562 13.6263C217.139 12.8712 218.868 12.4769 220.621 12.4731C228.929 12.4731 235.182 18.8618 235.182 28ZM211.519 28C211.519 33.3724 215.04 36.9555 219.708 36.9555C224.376 36.9555 227.925 33.3724 227.925 28C227.925 22.6277 224.404 19.0398 219.732 19.0398C215.059 19.0398 211.519 22.623 211.519 28Z" />
    <path d="M238.213 27.9998C238.213 18.8616 244.822 12.4728 253.682 12.4728C262.542 12.4728 269.152 18.8616 269.152 27.9998C269.152 37.138 262.538 43.5267 253.682 43.5267C244.827 43.5267 238.213 37.138 238.213 27.9998ZM261.871 27.9998C261.871 22.6227 258.355 19.0396 253.682 19.0396C249.01 19.0396 245.493 22.6227 245.493 27.9998C245.493 33.3768 249.01 36.9553 253.682 36.9553C258.355 36.9553 261.871 33.3722 261.871 27.9998Z" />
    <path d="M269.452 42.9273L280.92 27.5221L270.061 13.0678H278.555L285.531 22.3277L291.902 13.0678H300.396L289.537 27.7001L301 42.9273H292.506L284.865 32.7774L277.946 42.9273H269.452Z" />
    <path d="M18.4625 37.2085H44.1576V45.1991H17.7535C15.4216 45.1991 13.1127 44.7469 10.9584 43.8684C8.80415 42.9899 6.84681 41.7023 5.19819 40.079C3.54957 38.4558 2.24195 36.5288 1.35004 34.408C0.458124 32.2872 -0.000624347 30.0143 6.37735e-07 27.719H8.83629C8.83441 28.9645 9.08202 30.1982 9.56494 31.3494C10.0479 32.5006 10.7566 33.5469 11.6507 34.4282C12.5447 35.3096 13.6065 36.0088 14.7754 36.4859C15.9442 36.9629 17.1971 37.2085 18.4625 37.2085Z" />
    <path d="M52.965 37.2086H44.1525C44.1515 34.8243 43.2375 32.528 41.5926 30.7778C39.9477 29.0276 37.693 27.9522 35.2782 27.7659C35.1772 25.5373 34.2079 23.4325 32.5715 21.889C30.9351 20.3456 28.7575 19.482 26.4911 19.4778C24.2247 19.4736 22.0438 20.329 20.4015 21.8664C18.7592 23.4038 17.7817 25.5049 17.6722 27.7332H8.83594C9.05697 23.8842 10.5713 20.2167 13.1409 17.3073C15.7104 14.3979 19.1895 12.4117 23.0309 11.6609C26.8724 10.9101 30.8584 11.4373 34.3623 13.1597C37.8661 14.8821 40.689 17.7019 42.3872 21.1758C45.5376 22.559 48.2133 24.8112 50.0905 27.6599C51.9678 30.5086 52.9663 33.8318 52.965 37.2273V37.2086Z" />
  </svg>
);
