import * as yup from "yup";

export const signUpFormSchema = yup.object().shape({
  name: yup.string().required("This field is required").trim(),
  companyName: yup.string().trim(),
  website: yup
    .string()
    .trim()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      { excludeEmptyString: true, message: "Please enter а valid URL" }
    ),
  email: yup.string().required("This field is required").trim().email("Please enter a valid email address"),
  password: yup
    .string()
    .required("Password is a required field")
    .trim()
    .min(
      8,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    )
    .matches(
      /^(.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*){8,}$/,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Match password")
    .trim(),
});
