export const AllFeatures = () => {
  return (
    <div className="features-need">
      <div className="center">
        <h2 className="title h2">
          All the features you need <br />
          for a fraction of the cost
        </h2>

        <dl className="features-need__list">
          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="M67.08 58.33H43.21a8.75 8.75 0 0 0-5.3-5.3V40.9c3.93 0 7.36-.04 9.03-.07a14.44 14.44 0 0 0 13.9-11.17 14.62 14.62 0 0 0-10.38-17.5l-.5-.12c-.87-.2-1.31-.29-1.71-1.25a17.5 17.5 0 0 0-33.16 2.5 18.57 18.57 0 0 0-.16 7.97 10.2 10.2 0 0 0 3.74 19.57c1.9.05 7.39.08 13.1.08h.31v12.13a8.75 8.75 0 0 0-5.3 5.3H2.93a2.92 2.92 0 1 0 0 5.82h23.87a8.7 8.7 0 0 0 16.42 0h23.87a2.92 2.92 0 0 0 0-5.83ZM18.83 35a4.3 4.3 0 0 1-4.08-3.14 4.38 4.38 0 0 1 2.48-5.25 5.88 5.88 0 0 0 3.44-6.7 11.67 11.67 0 0 1 22.2-6.88 7.71 7.71 0 0 0 6.08 4.76 8.82 8.82 0 0 1 6.22 10.56A8.67 8.67 0 0 1 46.84 35c-2.03.03-25.8.07-28.01 0ZM35 64.16a2.92 2.92 0 1 1 0-5.83 2.92 2.92 0 0 1 0 5.83Z" />
              </svg>
              S3-compatible API
            </dt>

            <dd>Petabox is 100% S3 compatible. You can use 100% of S3 API features including versioning and ACL.</dd>
          </div>

          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="m55.01 13 2.84 2.93a3 3 0 1 0 4.31-4.17l-7.28-7.54a4 4 0 0 0-5.75 0l-7.29 7.54a3 3 0 0 0 4.32 4.17L49 12.98V24a3 3 0 1 0 6 0V13ZM7 15a8 8 0 0 1 8-8h17a3 3 0 1 1 0 6H15a2 2 0 0 0-2 2v40c0 1.1.9 2 2 2h17a3 3 0 1 1 0 6H15a8 8 0 0 1-8-8V15Zm50.85 39.07L55 57V46a3 3 0 0 0-6 0v11.02l-2.85-2.95a3 3 0 0 0-4.32 4.17l7.29 7.54a4 4 0 0 0 5.75 0l7.28-7.54a3 3 0 1 0-4.31-4.17Z" />
              </svg>
              High Redundancy
            </dt>
            <dd>
              Petabox replicates all the data 3x times across different data clouds to provide the highest data
              redundancy.
            </dd>
          </div>
          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="M8.75 61.6c-.6 0-1.17-.17-1.65-.5a15.66 15.66 0 0 1 1.48-27.13 22.3 22.3 0 0 1-2.33-15.1 23.33 23.33 0 0 1 45.18-2.46 3.16 3.16 0 0 0 2.1 2.14 23.28 23.28 0 0 1 15.5 15.61 2.92 2.92 0 0 1-5.59 1.67 17.47 17.47 0 0 0-11.62-11.7 9.04 9.04 0 0 1-5.96-5.99A17.5 17.5 0 1 0 13.6 31.3a5.82 5.82 0 0 1-2.3 7.84 9.83 9.83 0 0 0-.88 17.15 2.92 2.92 0 0 1-1.67 5.32ZM55.71 70c1.04-.05 2.08-.2 3.1-.46a14.43 14.43 0 0 0 10.79-10.8 14.62 14.62 0 0 0-10.38-17.5s-1.83-.42-2.23-1.37a17.5 17.5 0 0 0-33.16 2.5 18.6 18.6 0 0 0-.16 7.97 10.2 10.2 0 0 0 1.31 19.22c.8.23 1.61.38 2.44.44H55.7Zm-4.09-27.87a7.72 7.72 0 0 0 6.1 4.76 8.8 8.8 0 0 1 3.9 14.66 8.69 8.69 0 0 1-6.01 2.54c-4.86.1-24.13.1-28 0a4.29 4.29 0 0 1-4.1-3.13 4.35 4.35 0 0 1 2.47-5.24A5.88 5.88 0 0 0 29.42 49a11.64 11.64 0 0 1 10.55-14.04 11.67 11.67 0 0 1 11.64 7.17h.01Z" />
              </svg>
              Easy integration
            </dt>

            <dd>Switch over to Petabox in seconds. Just change credentials and URL in your config&nbsp;file.</dd>
          </div>

          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="M11.07 8A9.1 9.1 0 0 0 2 17.14v14.95a9.1 9.1 0 0 0 9.07 9.14H31.7v-4.98H11.07a4.14 4.14 0 0 1-4.12-4.16V17.14c0-2.3 1.85-4.16 4.12-4.16h41.25a4.14 4.14 0 0 1 4.13 4.16v4.43c1.5 1.82 3.27 3 4.95 3.76v-8.2A9.1 9.1 0 0 0 52.33 8H11.08ZM51.5 22.12c-.8 0-1.56.4-2.02 1.05l-.03.04a18.67 18.67 0 0 1-3.18 3.17 14.35 14.35 0 0 1-8.8 3.22A2.48 2.48 0 0 0 35 32.1v9.13c0 5.46 1.45 9.8 4.29 13.23 2.79 3.37 6.74 5.64 11.34 7.38.56.21 1.18.21 1.74 0 4.6-1.74 8.55-4 11.34-7.38C66.55 51.02 68 46.69 68 41.23V32.1c0-1.37-1.1-2.49-2.47-2.49-3.68 0-6.67-1.56-8.8-3.22a18.67 18.67 0 0 1-3.18-3.17l-.03-.03a2.47 2.47 0 0 0-2.02-1.06Zm-2.2 8.2c.86-.66 1.6-1.33 2.2-1.93.6.6 1.34 1.27 2.2 1.94a19.8 19.8 0 0 0 9.35 4.08v6.82c0 4.51-1.17 7.65-3.14 10.04-1.88 2.26-4.65 4.05-8.41 5.57-3.76-1.52-6.53-3.3-8.41-5.57-1.97-2.39-3.14-5.53-3.14-10.04v-6.82a19.8 19.8 0 0 0 9.35-4.08Zm.18-7.15v.01Z" />
              </svg>
              DDoS Protection
            </dt>

            <dd>Access to all files is protected from DDoS attacks to ensure uninterrupted access.</dd>
          </div>

          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="M55.42 24.57v-4.15a20.42 20.42 0 1 0-40.84 0v4.15a14.58 14.58 0 0 0-8.75 13.35v17.5A14.6 14.6 0 0 0 20.42 70h29.16a14.6 14.6 0 0 0 14.59-14.58v-17.5a14.58 14.58 0 0 0-8.75-13.35Zm-35-4.15a14.58 14.58 0 0 1 29.16 0v2.91H20.42v-2.91Zm37.91 35a8.75 8.75 0 0 1-8.75 8.75H20.42a8.75 8.75 0 0 1-8.75-8.75v-17.5a8.75 8.75 0 0 1 8.75-8.75h29.16a8.75 8.75 0 0 1 8.75 8.75v17.5Z" />
                <path d="M35 40.83a2.92 2.92 0 0 0-2.92 2.92v5.83a2.92 2.92 0 1 0 5.84 0v-5.83A2.92 2.92 0 0 0 35 40.83Z" />
              </svg>
              Ransomware Protection
            </dt>

            <dd>
              Petabox makes your data immutable: once written, they cannot be changed and cannot be encrypted by
              ransomware.
            </dd>
          </div>

          <div className="features-need__item">
            <dt className="h3">
              <svg width="70" height="70" viewBox="0 0 70 70">
                <path d="M33.74 6.34c.7-.67 1.82-.67 2.52 0 4.02 3.86 9.75 5.95 14.64 7.06a52 52 0 0 0 8.54 1.18h.03c.99.03 1.78.84 1.78 1.83v14.22a38 38 0 0 1-.24 4.28c-.99-.94-2.17-1.7-3.47-2.18a35 35 0 0 0 .06-2.1v-12.5l-.95-.1a55.64 55.64 0 0 1-6.56-1.08C45.4 15.9 39.58 13.9 35 10.1c-4.58 3.79-10.4 5.79-15.1 6.85a55.63 55.63 0 0 1-7.5 1.17v12.14c0 6.44 1.65 12.71 5.32 18.01C21.31 53.44 26.9 57.8 35 60.43c1.01-.33 1.98-.69 2.92-1.07v3.35c0 .19 0 .37.02.56-.78.29-1.58.56-2.4.81-.35.11-.73.11-1.08 0-9.1-2.84-15.56-7.71-19.73-13.73-4.17-6-5.98-13.03-5.98-20.09V16.41c0-1 .79-1.8 1.78-1.83h.03a12.29 12.29 0 0 0 .6-.02c.42-.03 1.04-.07 1.81-.15a52 52 0 0 0 6.13-1.01c4.89-1.11 10.62-3.2 14.64-7.06Zm12.93 37.41V42.3a7.3 7.3 0 1 1 14.58 0v1.46h1.46a4.37 4.37 0 0 1 4.37 4.38V62.7a4.37 4.37 0 0 1-4.37 4.37H45.2a4.38 4.38 0 0 1-4.38-4.37V48.13a4.37 4.37 0 0 1 4.38-4.38h1.46Zm3.64-1.46v1.46h7.3V42.3a3.65 3.65 0 0 0-7.3 0Zm8.02 13.13a4.37 4.37 0 1 0-8.75 0 4.37 4.37 0 0 0 8.75 0Z" />
              </svg>
              End-to-End Encryption
            </dt>

            <dd>
              Our encryption technology prevents third parties from accessing your data and protects your privacy.
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
