import { useMemo } from "react";
import { Link } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";

export const Footer = ({ backgroundColor }: { backgroundColor?: string }) => {
  const legals = useMemo(
    () => [
      { title: "Customer Agreement", href: routesEnum.agreement },
      { title: "Privacy Policy", href: routesEnum.privacyPolicy },
      { title: "Cookie Policy", href: routesEnum.cookiePolicy },
      { title: "Terms of Service", href: routesEnum.termsOfService },
    ],
    []
  );

  const socials = useMemo(
    () => [
      {
        title: "Telegram",
        href: "https://t.me/Peta_Box",
        icon: (
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="m35.1 15.77-3.51 17.69s-.5 1.23-1.85.64l-8.13-6.23c1.1-.98 9.58-8.6 9.95-8.95.57-.54.21-.86-.45-.45l-12.53 7.95-4.84-1.62s-.76-.27-.83-.86c-.07-.6.86-.91.86-.91l19.7-7.73s1.63-.71 1.63.47z" />
          </svg>
        ),
      },
      {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/petaboxio",
        icon: (
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="M13.3 20.96h4.6V35.8h-4.6zm-.43-4.69a2.73 2.73 0 0 1 2.72-2.74c1.5 0 2.71 1.23 2.71 2.74a2.73 2.73 0 0 1-2.71 2.74 2.73 2.73 0 0 1-2.72-2.74zm12.38 19.54h-4.4V20.96h4.4v2s1.33-2.45 4.48-2.45c3.15 0 5.4 1.92 5.4 5.9v9.4h-4.57v-7.8c0-2.13-.82-3.33-2.5-3.33-1.84 0-2.8 1.25-2.8 3.34z" />
          </svg>
        ),
      },
      {
        title: "WhatsUp",
        href: "http://wa.me/12029607440",
        icon: (
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="M33.37 14.36a13.75 13.75 0 0 0-9.79-4.06 13.86 13.86 0 0 0-12 20.76l-1.96 7.17 7.34-1.93a13.86 13.86 0 0 0 16.4-21.94Zm-9.79 21.29c-2.06 0-4.09-.55-5.86-1.6l-.42-.25-4.35 1.14 1.16-4.25-.27-.43a11.52 11.52 0 1 1 20.38-10.52 11.52 11.52 0 0 1-10.63 15.91Zm6.31-8.62a56.6 56.6 0 0 0-2.36-1.12c-.32-.12-.55-.17-.78.17-.23.35-.9 1.12-1.1 1.36-.2.23-.4.25-.74.08-.35-.17-1.46-.54-2.79-1.71a10.42 10.42 0 0 1-1.92-2.4c-.2-.35-.02-.53.15-.7.16-.16.35-.41.52-.61.17-.2.23-.35.35-.58.11-.23.06-.43-.03-.6-.09-.18-.78-1.88-1.07-2.57-.28-.67-.56-.58-.78-.6h-.66a1.27 1.27 0 0 0-.92.43c-.32.34-1.21 1.18-1.21 2.88s1.24 3.35 1.41 3.58c.17.23 2.44 3.72 5.9 5.22.83.36 1.48.57 1.98.73.83.26 1.58.23 2.18.14.66-.1 2.04-.84 2.33-1.65.3-.8.3-1.5.2-1.64-.08-.15-.31-.23-.66-.4Z" />
          </svg>
        ),
      },
      {
        title: "Facebook",
        href: "https://www.facebook.com/PetaBoxStorage",
        icon: (
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="M26.1 38.67V25.92h4.14l.62-5h-4.77v-3.17c0-1.44.4-2.43 2.38-2.43H31v-4.45a32.3 32.3 0 0 0-3.7-.2c-3.66 0-6.18 2.32-6.18 6.58v3.67H17v5h4.13v12.75h4.96Z" />
          </svg>
        ),
      },
      {
        title: "Twitter",
        href: "https://twitter.com/petabox_io",
        icon: (
          <svg width="48" height="48" viewBox="0 0 48 48">
            <path d="M34.33 18.9c.02.24.02.46.02.7 0 7-5.37 15.07-15.17 15.07-3.01 0-5.82-.87-8.18-2.38a10.71 10.71 0 0 0 7.9-2.2 5.36 5.36 0 0 1-4.98-3.67 5.7 5.7 0 0 0 2.41-.1 5.33 5.33 0 0 1-4.27-5.2v-.07c.7.4 1.53.64 2.4.68a5.3 5.3 0 0 1-1.65-7.09 15.14 15.14 0 0 0 11 5.55 5.27 5.27 0 0 1 3.16-6.11 5.35 5.35 0 0 1 5.92 1.27c1.2-.23 2.34-.67 3.38-1.28a5.3 5.3 0 0 1-2.34 2.92c1.06-.12 2.1-.4 3.07-.82a11.4 11.4 0 0 1-2.67 2.74Z" />
          </svg>
        ),
      },
    ],
    []
  );

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <div className="center">
        <div className="footer__top">
          <Link to={routesEnum.index} onClick={scrollTop} className="footer__logo">
            <svg width="172" height="32" viewBox="0 0 216 40">
              <use xlinkHref="#svg-logo" />
            </svg>
          </Link>
          <div className="footer__menu">
            <p className="footer__head">Offices</p>
            <ul>
              <li>
                <a href="https://goo.gl/maps/CJaXhXnWWC62PhkF7" target="_blank" rel="noopener nofollow">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="m18.16 16.88-1.19 1.18-3.4 3.3c-.88.85-2.26.85-3.13 0l-3.5-3.39-1.1-1.09a8.7 8.7 0 1 1 12.32 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" />
                  </svg>
                  <span>
                    2261 Market Street #4000 <br />
                    San Francisco CA, 94114
                  </span>
                </a>
              </li>
              <li>
                <a href="https://goo.gl/maps/21M8PzvzCLPSWvWv9" target="_blank" rel="noopener nofollow">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="m18.16 16.88-1.19 1.18-3.4 3.3c-.88.85-2.26.85-3.13 0l-3.5-3.39-1.1-1.09a8.7 8.7 0 1 1 12.32 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" />
                  </svg>

                  <span>
                    Aiolou & Panagioti Diomidous, <br />9 Katholiki, 3020, Limassol, Cyprus
                  </span>
                </a>
              </li>

              <li>
                <a href="https://goo.gl/maps/btktyKGCrqQuZsFr8" target="_blank" rel="noopener nofollow">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="m18.16 16.88-1.19 1.18-3.4 3.3c-.88.85-2.26.85-3.13 0l-3.5-3.39-1.1-1.09a8.7 8.7 0 1 1 12.32 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" />
                  </svg>

                  <span>
                    7 Temasek Boulevard #12-07 <br />
                    Suntec Tower One, Singapore, 038987
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer__menu">
            <p className="footer__head">Contacts</p>

            <ul>
              <li>
                <a href="tel:+35799160042">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="m20.49 17.14-4.07-3.7a1 1 0 0 0-1.39.05l-2.4 2.46a5.74 5.74 0 0 1-2.92-1.66 5.71 5.71 0 0 1-1.66-2.93l2.46-2.4a1 1 0 0 0 .04-1.38L6.85 3.5a1 1 0 0 0-1.38-.08L3.3 5.29a1 1 0 0 0-.3.65c0 .25-.3 6.17 4.3 10.76A15.14 15.14 0 0 0 18.06 21a1 1 0 0 0 .65-.3l1.86-2.17a1 1 0 0 0-.08-1.39Z" />
                  </svg>

                  <span>+35799160042</span>
                </a>
              </li>

              <li>
                <a href="mailto:support@petabox.io">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M22 8.6v8.15a3.25 3.25 0 0 1-3.07 3.24l-.18.01H5.25a3.25 3.25 0 0 1-3.24-3.07L2 16.75V8.61l9.65 5.05c.22.12.48.12.7 0L22 8.61ZM5.25 4h13.5a3.25 3.25 0 0 1 3.23 2.92L12 12.15 2.02 6.92a3.25 3.25 0 0 1 3.04-2.91L5.25 4h13.5-13.5Z" />
                  </svg>

                  <span>support@petabox.io</span>
                </a>
              </li>

              <li>
                <a href="tg://resolve?domain=petabox_sales">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M20.66 3.72 2.93 10.55c-1.2.49-1.2 1.16-.22 1.47l4.55 1.42L17.8 6.79c.5-.3.95-.14.58.2l-8.54 7.7-.31 4.69c.46 0 .66-.21.92-.46l2.21-2.15 4.6 3.4c.85.46 1.46.22 1.67-.8l3.02-14.22c.3-1.24-.48-1.8-1.29-1.43Z" />
                  </svg>

                  <span>@petabox_official</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer__menu">
            <p className="footer__head">Legal</p>

            <ul>
              {legals.map((x) => (
                <li key={x.title}>
                  <Link to={x.href}>{x.title}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__menu">
            <p className="footer__head">Support</p>

            <ul>
              <li>
                <a href="https://docs.petabox.io/" target="_blank" rel="noopener nofollow">
                  Docs
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <div className="footer__bottom">
          <div onClick={scrollTop} className="footer__logo">
            <svg width="172" height="32" viewBox="0 0 216 40">
              <g id="svg-logo">
                <path d="M57.7 40V9.33h5.23v2.68a8.3 8.3 0 0 1 6.53-3.11c5.97 0 10.45 4.56 10.45 11.09 0 6.52-4.48 11.09-10.45 11.09a8.47 8.47 0 0 1-6.53-3.12V40l-5.22.01ZM74.7 20c0-3.84-2.53-6.4-5.88-6.4-3.36 0-5.88 2.56-5.88 6.4 0 3.84 2.52 6.4 5.88 6.4 3.35 0 5.88-2.56 5.88-6.4ZM87.23 21.7c.56 3.12 2.65 4.9 5.87 4.9 2.31 0 3.66-.85 4.36-2.33h5.66c-1.3 4.43-5.1 6.82-10.24 6.82-6.23 0-10.8-4.56-10.8-11.09s4.57-11.09 10.8-11.09 10.8 4.56 10.8 11.1c0 .59-.05 1.14-.1 1.7H87.23Zm.04-3.62H98.5c-.56-2.99-2.57-4.69-5.61-4.69-3.05 0-5.06 1.7-5.62 4.69ZM117.18 26.4c.81 0 1.62-.14 2.39-.39v4.57c-1.12.33-2.27.5-3.44.51-4.31 0-7.45-1.67-7.45-7.38v-9.9h-3.48V9.33h3.49V3.47h5.22v5.86h5.66v4.48h-5.66v9.56c0 2.1 1 3.03 3.27 3.03ZM122.4 24.95c0-9.17 14.15-5.08 14.15-9.34 0-1.67-1.74-2.65-4-2.65-2.4 0-4.06 1.24-4.06 3.63h-5.43c0-5.55 4.6-7.68 9.48-7.68 4.79 0 9.22 1.92 9.22 6.9v14.85h-5.12v-3.15c-1.39 2.51-3.92 3.58-6.92 3.58-3.75 0-7.32-1.83-7.32-6.14Zm8.4 1.86c3.05 0 5.84-1.7 5.84-4.95v-.98c-3.48 1.4-8.88.98-8.88 3.71 0 1.51 1.31 2.22 3.04 2.22ZM168.77 20c0 6.53-4.49 11.09-10.45 11.09a8.49 8.49 0 0 1-6.53-3.11v2.67h-5.23V0h5.23v12.02a8.3 8.3 0 0 1 6.53-3.11c5.96 0 10.45 4.56 10.45 11.09Zm-16.98 0c0 3.84 2.52 6.4 5.87 6.4s5.9-2.56 5.9-6.4c0-3.84-2.53-6.4-5.88-6.4s-5.9 2.56-5.9 6.4ZM170.94 20c0-6.53 4.75-11.1 11.1-11.1 6.36 0 11.1 4.57 11.1 11.1 0 6.53-4.74 11.09-11.1 11.09-6.35 0-11.1-4.56-11.1-11.1Zm16.98 0c0-3.84-2.52-6.4-5.88-6.4-3.35 0-5.87 2.56-5.87 6.4 0 3.84 2.52 6.4 5.88 6.4 3.35 0 5.87-2.56 5.87-6.4ZM193.36 30.66l8.23-11-7.8-10.33h6.1l5 6.62 4.58-6.62h6.1l-7.8 10.46L216 30.66h-6.1l-5.48-7.25-4.96 7.25h-6.1ZM13.25 26.57h18.44v5.71H12.74a12.97 12.97 0 0 1-9.01-3.65A12.47 12.47 0 0 1 0 19.8h6.34a6.65 6.65 0 0 0 2.02 4.79 6.92 6.92 0 0 0 4.89 1.98Z"></path>
                <path d="M38 26.58h-6.32a6.7 6.7 0 0 0-1.83-4.6 6.96 6.96 0 0 0-4.53-2.15 6.14 6.14 0 0 0-1.95-4.2 6.4 6.4 0 0 0-8.73-.01 6.14 6.14 0 0 0-1.96 4.19H6.34a12.29 12.29 0 0 1 3.09-7.45A12.91 12.91 0 0 1 24.66 9.4a12.54 12.54 0 0 1 5.76 5.72c2.26 1 4.18 2.6 5.52 4.64a12.37 12.37 0 0 1 2.07 6.83v-.01ZM35.92"></path>
              </g>
            </svg>
          </div>

          <ul className="footer__social">
            {socials.map((x) => (
              <li key={x.title}>
                <a href={x.href} target="_blank" rel="nofollow noopener" title={x.title}>
                  {x.icon}
                </a>
              </li>
            ))}
          </ul>

          <div className="footer__copyright">
            <p>Petabox © 2022. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
