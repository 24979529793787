import { PrivacyPolicy } from "Containers/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "Components/ScrollToTop/ScrollToTop";
import { Startup } from "Components/Startup/Startup";
import { EmailVerify } from "Containers/EmailVerify/EmailVerify";
import { Sla } from "Containers/Sla/Sla";
import { Redirect } from "Containers/Redirect/Redirect";
import { RecoverPassword } from "Containers/RestorePassword/RecoverPassword";
import { ResetPassword } from "Containers/RestorePassword/ResetPassword";
import { SignIn } from "Containers/SignIn/SignIn";
import { SignUp } from "Containers/Signup/SignUp";
import { Terms } from "Containers/Terms/Terms";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";
import { DevUiPage } from "Containers/DevUiPage";
import { Cookies } from "./Containers/Cookies/Cookies";
import { Main } from "Containers/main/Main";
import { Auth } from "Containers/Auth";
import { B2b } from "./Containers/B2b/B2b";
import {Agreement} from "./Containers/Agreement/Agreement";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          <Auth>
            <ScrollToTop>
              <Routes>
                <Route path={routesEnum.index} element={<Main />} />
                <Route path={routesEnum.b2b} element={<B2b />} />
                <Route path={routesEnum.sla} element={<Sla />} />
                <Route path={routesEnum.cookiePolicy} element={<Cookies />} />
                <Route path={routesEnum.privacyPolicy} element={<PrivacyPolicy />} />
                <Route path={routesEnum.agreement} element={<Agreement />} />
                <Route path={routesEnum.startup} element={<Startup />} />
                <Route path={routesEnum.termsOfService} element={<Terms />} />
                <Route path={routesEnum.signup} element={<SignUp />} />
                <Route path={routesEnum.signin} element={<SignIn />} />
                {/* <Route path={routesEnum.emailVerify} element={<EmailVerify />} /> */}
                <Route path={routesEnum.providerRedirect} element={<Redirect />} />
                <Route path={routesEnum.emailRegistration} element={<Redirect />} />
                <Route path={routesEnum.recoverPassword} element={<RecoverPassword />} />
                <Route path={routesEnum.resetPassword} element={<ResetPassword />} />
                <Route path="/dev-ui" element={<DevUiPage />} />
              </Routes>
            </ScrollToTop>
          </Auth>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
