import { ReactElement } from "react";

type PopUpProps = {
  show: boolean;
  title: string;
  description?: string;
  icon?: ReactElement;
  actionTitle: string;
  action: () => void;
};

export const PopUp = ({ title, description, actionTitle, action, icon, show }: PopUpProps) => {
  return show ? (
    <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-border-gray">
      <div className="bg-white max-h-[352px] max-w-[478px] w-full p-[40px] text-center rounded-[10px]">
        {icon && <div className="flex justify-center mb-[24px]">{icon}</div>}
        <h2 className="text-[28px] leading-[32px] mb-[16px] font-second-font font-semibold">{title}</h2>
        <p className="text-[12px] leading-[24px] text-theme-gray">{description}</p>
        <button
          className="w-full text-[16px] leading-[24px] bg-theme-blue text-white rounded-[10px] h-[48px] mt-[24px] focus:bg-theme-blue-press hover:bg-theme-blue-hover font-second-font font-semibold"
          onClick={action}
        >
          {actionTitle}
        </button>
      </div>
    </div>
  ) : null;
};
