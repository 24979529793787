import {Layout} from "Containers/Layout/Layout";
import React from "react";
import {AllFeatures} from "./partials/AllFeatures";
import {CalculatorBlock} from "./partials/Calculator";
import {CiteFeldMan} from "./partials/CiteFeldman";
import {Faq} from "./partials/Faq";
import {FirstScreen} from "./partials/FirstScreen";
import {Pricing} from "./partials/Pricing";
import {StorageNeeds} from "./partials/StorageNeeds";
import {StorageTools} from "./partials/StorageTools";
import {Helmet} from "react-helmet";


export const Main = () => {
  return (
    <Layout>
      <Helmet>
        <title>Petabox: S3 Compatible Object Storage</title>
        <meta name="description" content="Petabox: Data Storage Discounter"/>
      </Helmet>
      <FirstScreen/>
      <AllFeatures/>
      <CiteFeldMan/>
      <StorageNeeds/>
      <Pricing/>
      <CalculatorBlock/>
      <StorageTools/>
      <Faq/>
    </Layout>
  );
};
