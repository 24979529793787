import React from "react";
import {Link} from "react-router-dom";
import {routesEnum} from "Routes/routesEnum";

export const Header = () => {
  return (
    <header className="
    py-[10rem]
    2xlm:py-[50px]
    2xlm:px-0
    mdm:py-[30px]
    mdm:px-0
    ">
      <div className="
      w-full
      max-w-[1470px]
      px-[15px]
      2xlm:max-w-full
      mdm:max-w-[680px]
      mx-auto
      flex
      items-center
      justify-between
      ">
        <Link to={routesEnum.index} className="logo">
          <img src={require("../../Images/logo_blue.svg").default} alt="logo_blue"/>
        </Link>
        <Link to={routesEnum.index} className="
        text-20
        text-theme-black
        flex
        items-center
        hover:text-theme-blue
        font-semibold
        ">
          <span
            className="
            w-[24px]
            h-[24px]
            mr-[16px]
            flex-none
          ">
            <svg
              className="
              w-full
              h-full
            " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.121 9.06911L15.536 1.48311C14.5973 0.5471 13.3257 0.0214844 12 0.0214844C10.6744 0.0214844 9.40277 0.5471 8.46401 1.48311L0.879012 9.06911C0.599438 9.34689 0.377782 9.67741 0.226895 10.0415C0.0760072 10.4056 -0.0011104 10.796 1.20795e-05 11.1901V21.0071C1.20795e-05 21.8028 0.316083 22.5658 0.878692 23.1284C1.4413 23.691 2.20436 24.0071 3.00001 24.0071H21C21.7957 24.0071 22.5587 23.691 23.1213 23.1284C23.6839 22.5658 24 21.8028 24 21.0071V11.1901C24.0011 10.796 23.924 10.4056 23.7731 10.0415C23.6222 9.67741 23.4006 9.34689 23.121 9.06911V9.06911ZM15 22.0071H9.00001V18.0731C9.00001 17.2775 9.31608 16.5144 9.87869 15.9518C10.4413 15.3892 11.2044 15.0731 12 15.0731C12.7957 15.0731 13.5587 15.3892 14.1213 15.9518C14.6839 16.5144 15 17.2775 15 18.0731V22.0071ZM22 21.0071C22 21.2723 21.8947 21.5267 21.7071 21.7142C21.5196 21.9018 21.2652 22.0071 21 22.0071H17V18.0731C17 16.747 16.4732 15.4753 15.5355 14.5376C14.5979 13.5999 13.3261 13.0731 12 13.0731C10.6739 13.0731 9.40216 13.5999 8.46448 14.5376C7.5268 15.4753 7.00001 16.747 7.00001 18.0731V22.0071H3.00001C2.7348 22.0071 2.48044 21.9018 2.29291 21.7142C2.10537 21.5267 2.00001 21.2723 2.00001 21.0071V11.1901C2.00094 10.9251 2.1062 10.6711 2.29301 10.4831L9.87801 2.90011C10.4417 2.33904 11.2047 2.02405 12 2.02405C12.7953 2.02405 13.5583 2.33904 14.122 2.90011L21.707 10.4861C21.8931 10.6734 21.9983 10.9261 22 11.1901V21.0071Z" fill="currentColor"/>
            </svg>
          </span>
          Home
        </Link>
      </div>
    </header>
  )
}
