import { useCallback, useEffect, useRef, useState } from "react";

export function useShowApiError() {
  const [error, setError] = useState("");
  const mountedRef = useRef(true);

  const handleSetError = useCallback((error?: string) => {
    if (!mountedRef.current) return;
    setError(error);
  }, []);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [error, handleSetError] as [string, (error?: string) => void];
}
