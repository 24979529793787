import { handleApiError } from "Helpers/handeApiError";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { getUserProfile } from "Services/auth-service";
import { UserState } from "store/UserState";

export const Auth: ReactFC = ({ children }) => {
  const setUserProfile = useSetRecoilState(UserState);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const userProfile = await getUserProfile();
        setUserProfile(userProfile);
      } catch (e) {
        const { code } = handleApiError(e);
        if (code !== 401) throw new Error(e);
      }
    };
    checkUser();
  }, []);

  return <>{children}</>;
};
