import * as yup from "yup";

const SelectOptionYup = (label?: string) => {
  return yup.object().required().label(label);
};

export const signUpB2bFormSchema = yup.object().shape({
  firstName: yup.string().required("This field is required").trim(),
  lastName: yup.string().required("This field is required").trim(),
  companyName: yup.string().trim(),
  email: yup
    .string()
    .required("This field is required")
    .trim()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!ovomail.co)(?!delivrmail.com)(?!mailto.plus)(?!fexpost.com)(?!fexbox.org)(?!mailbox.in.ua)(?!rover.info)(?!inpwa.com)(?!intopwa.com)(?!tofeat.com)(?!chitthi.in)(?!haboty.com)(?!dicopto.com)(?!haizail.com)(?!charav.com)(?!inkmoto.com)(?!imdutex.com)(?!mozej.com)(?!mailna.co)(?!mailna.in)(?!mailna.me)(?!mohmal.im)(?!mohmal.in)(?!yuduma.com)(?!ekamaz.com)(?!jumass.com)(?!labworld.org)(?!mentonit.net)(?!vintomaper.com)(?!ursorvutr.com)(?!doitups.com)(?!fallinhay.com)(?!rocketestate724.com)(?!dragonhospital.net)(?!cloud-mail.top)(?!coooooool.com)(?!crossmailjet.com)(?!kobrandly.com)(?!plancetose.com)(?!coffeetimer24.com)(?!crossmailjet.com)(?!kobrandly.com)(?!blondemorkin.com)(?!block521.com)(?!best-john-boats.com)(?!popcornfly.com)(?!plancetose.com)(?!coooooool.com)(?!waterisgone.com)(?!paperpapyrus.com)(?!vursa.com)(?!spacehotline.com)(?!tmail1.com)(?!tmail2.com)(?!tmail3.com)(?!tmail4.com)(?!tmail5.com)(?!tmail6.com)(?!tmail7.com)(?!tmail8.com)(?!tmail9.com)(?!tmail0.com)(?!kellychibale-researchgroup-uct.com)(?!teleg.eu)(?!mailo.icu)(?!decabg.eu)(?!ema-sofia.eu)(?!cyclelove.cc)(?!imdutex.com)(?!haboty.com)(?!charav.com)(?!haizail.com)(?!kaimdr.com)(?!inkmoto.com)(?!ekbasia.com)(?!dicopto.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/,
      "Please enter a corporate email"
    ),
  country: SelectOptionYup(),
  phone: yup.string().trim(),
  storageAmount: SelectOptionYup(),
  password: yup
    .string()
    .required("Password is a required field")
    .trim()
    .min(
      8,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    )
    .matches(
      /^(.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*){8,}$/,
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Match password")
    .trim(),
});
