export const Google = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8254 12.2353H22.1005V12.1979H14.0002V15.7979H19.0867C18.3447 17.8935 16.3507 19.3978 14.0002 19.3978C11.018 19.3978 8.6001 16.98 8.6001 13.9979C8.6001 11.0158 11.018 8.598 14.0002 8.598C15.3768 8.598 16.6292 9.11729 17.5828 9.96553L20.1285 7.41991C18.5211 5.92188 16.3709 4.99805 14.0002 4.99805C9.02986 4.99805 5 9.02774 5 13.9979C5 18.9681 9.02986 22.9978 14.0002 22.9978C18.9706 22.9978 23.0005 18.9681 23.0005 13.9979C23.0005 13.3945 22.9384 12.8054 22.8254 12.2353Z"
      fill="#FFC107"
    />
    <path
      d="M6.03784 9.80893L8.99487 11.9774C9.79499 9.99658 11.7327 8.598 14.0004 8.598C15.3769 8.598 16.6293 9.11729 17.5829 9.96553L20.1286 7.41991C18.5212 5.92188 16.371 4.99805 14.0004 4.99805C10.5434 4.99805 7.54538 6.94967 6.03784 9.80893Z"
      fill="#FF3D00"
    />
    <path
      d="M14 22.9979C16.3248 22.9979 18.4371 22.1082 20.0342 20.6615L17.2487 18.3044C16.3147 19.0147 15.1734 19.3988 14 19.3979C11.6591 19.3979 9.67135 17.9053 8.92253 15.8223L5.98755 18.0835C7.47709 20.9981 10.5021 22.9979 14 22.9979Z"
      fill="#4CAF50"
    />
    <path
      d="M22.8257 12.2353H22.1007V12.198H14.0005V15.7979H19.087C18.732 16.7953 18.0926 17.6668 17.2478 18.3049L17.2491 18.304L20.0347 20.661C19.8376 20.8401 23.0007 18.4979 23.0007 13.998C23.0007 13.3945 22.9386 12.8055 22.8257 12.2353Z"
      fill="#1976D2"
    />
  </svg>
);
