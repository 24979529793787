import React from "react";
import { Control, Controller, Path, Primitive } from "react-hook-form";

type NestedImpl<K extends string | number, V, T> = V extends T
  ? K
  : V extends Primitive | Array<infer V>
  ? never
  : `${K}.${NestedByType<V, T>}`;

// prettier-ignore
export type NestedByType<O, T> = {
  [K in keyof O]-?: K extends string ? NestedImpl<K, O[K], T> : never
}[keyof O];

type CtrlBase<TData, TValue = never> = {
  control: Control<TData>;
  name: NestedByType<TData, TValue>;
  label?: string;
  labelRight?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  iconPostion?: "start" | "end";
  icon?: JSX.Element;
};

export type CtrlProps<TData, TValue, Other> = Omit<CtrlBase<TData, TValue>, keyof Other> & Other;

type CtrlWrapperProps<TData, TValue> = Pick<CtrlBase<TData, TValue>, "control" | "name"> & {
  render: (value: TValue, onChange: (newValue: TValue) => void, error: string | undefined) => JSX.Element;
  errorKey?: string;
};

export const getControlledError = (formState: { errors: Record<string, any> }, name: string) => {
  const tokens = name.split(".");

  let value = formState.errors;
  for (const token of tokens) {
    if (value[token]) value = value[token];
  }
  return value && value.message ? (value.message as string) : undefined;
};

export function CtrlWrapper<TData, TValue>(props: CtrlWrapperProps<TData, TValue>) {
  const { control, name, render, errorKey = name } = props;

  return (
    <Controller
      control={control}
      name={name as unknown as Path<TData>}
      render={({ field: { value, onChange }, formState }) => {
        const error = getControlledError(formState, errorKey.toString());
        return render(value as TValue, onChange, error);
      }}
    />
  );
}
