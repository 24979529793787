import { AsyncButton } from "Components/Buttons/AsyncButton";
import { ButtonSocial } from "Components/Buttons/ButtonSocial";
import { Facebook } from "Images/Icons/Facebook";
import { Google } from "Images/Icons/Google";
import { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { loginFB, loginGoogle } from "Services/auth-service";
import { SelectedPlanState } from "store/store";

type FormBlockProps = {
  title?: string;
  submitText?: string;
  submitHandler?: () => void;
  children: ReactElement;
  subTitle?: ReactElement | string;
  showSocial?: boolean;
};

export const FormBlock = ({ title, subTitle, children, submitHandler, submitText, showSocial }: FormBlockProps) => {
  const tariffId = useRecoilValue(SelectedPlanState);
  return (
    <form className="px-[40px] smm:px-[20px] py-[40px] bg-white rounded-[10px]">
      {title && <h2 className="font-second-font font-semibold text-28 mb-[16px]">{title}</h2>}
      {subTitle && <h3 className="text-12 mb-[24px] text-theme-gray">{subTitle}</h3>}
      {children}
      {submitHandler && submitText && (
        <AsyncButton id="signup" onClick={submitHandler}>
          {submitText}
        </AsyncButton>
      )}

      <div>
        {title && title !== "Password recovery" && title !== "Recover password" && (
          <>
            <p className="font-second-font font-semibold text-center text-[14px] leading-[24px] my-[4px]">or</p>
            <div className="flex flex-col gap-y-4 mb-[122px] md:mb-0 md:flex-row gap-x-5">
              <ButtonSocial
                id="continue_google"
                className="md:text-12 gap-x-[10px] md:w-full"
                onClick={() => loginGoogle(tariffId)}
              >
                <Google />
                Continue with Google
              </ButtonSocial>
              {/* <ButtonSocial className="md:text-12 gap-x-[10px] md:w-full" onClick={loginFB}>
                <Facebook />
                Continue with Facebook
              </ButtonSocial> */}
            </div>
          </>
        )}
      </div>
    </form>
  );
};
