import { Loader } from "Components/Loader/Loader";
import { LocalStorage } from "Helpers/localStorage";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";

export const Redirect = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const error = searchParams.get("error");
  const accessToken = searchParams.get("accessToken");
  const TOKEN = token || accessToken;

  useEffect(() => {
    try {
      // @ts-ignore
      window.dataLayer.push({
        event: "Page - email_registration",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (TOKEN) {
      LocalStorage.setItem("accessToken", TOKEN);
      window.location.replace("/dashboard");
    }
  }, [TOKEN]);

  return (
    <div className="flex justify-center items-center w-full h-screen">
      {error ? (
        <p className="text-[20px] text-center">
          {error} <br />{" "}
          <Link className="underline text-theme-blue" to={routesEnum.index}>
            Return to main page
          </Link>{" "}
        </p>
      ) : (
        <Loader />
      )}
    </div>
  );
};
