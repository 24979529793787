import axios, { AxiosRequestConfig } from "axios";
import { config } from "common/config";
import { LocalStorage } from "Helpers/localStorage";

export const clientApi = axios.create({
  baseURL: config.authApiUrl,
  withCredentials: true,
});

export const authClientApi = axios.create({
  baseURL: config.authApiUrl,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf8",
  },
});

const apiCall = async <T>(params: AxiosRequestConfig): Promise<T> => {
  const token = LocalStorage.getItem<string>("accessToken");
  if (token) authClientApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const rep = await authClientApi(params);
  return rep.data as T;
};

export const apiGet = async <T>(url: string, params?: AxiosRequestConfig): Promise<T> => {
  return apiCall({ url, method: "GET", ...params });
};
