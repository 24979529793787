import { FormSelector, FormSelectorProps, OptionType } from "../Form/FormSelector";
import { CtrlWrapper, CtrlProps } from "./ControllWrapper";

type Props = Omit<FormSelectorProps<OptionType>, "onChange" | "value" | "error">;

export const ControlledSelect = <T,>(props: CtrlProps<T, OptionType, Props>) => {
  const { name, control, options, ...inputProps } = props;

  return (
    <CtrlWrapper
      name={name}
      control={control}
      render={(value, onChange, error) => {
        return <FormSelector error={error} value={value} onChange={onChange} options={options} {...inputProps} />;
      }}
    />
  );
};
