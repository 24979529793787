import clsx from "clsx";
import { Eye } from "Images/Icons/Eye";
import { EyeCrossed } from "Images/Icons/EyeCrossed";
import { useState } from "react";

export type FormInputProps = {
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  className?: string;
  autocomplete?: boolean;
  name: string;
};

export const FormInput = ({
  label,
  type,
  placeholder,
  required,
  value,
  name,
  onChange,
  error,
  className,
  autocomplete,
}: FormInputProps) => {
  const [typeState, setTypeState] = useState(type);
  const changeType = () => setTypeState(typeState === "password" ? "text" : "password");

  return (
    <label className="block">
      <div className="relative">
        {label && (
          <span className="text-[12px] leading-[2px] px-[4px] font-medium text-theme-gray absolute top-0 left-[20px] translate-y-[-50%] bg-white">
            {label} {required && <span className="text-theme-error">&bull;</span>}
          </span>
        )}
        <input
          required={required}
          onChange={onChange}
          type={typeState}
          name={name}
          // @ts-ignore prevent chrome autofill
          autocomplete={autocomplete ? "on" : "new-password"}
          /* avoid Warning:
            'A component is changing an uncontrolled input to be controlled.
            This is likely caused by the value changing from undefined '
            by using value={value || ""} <- empty string
          */
          value={value || ""}
          className={clsx(
            "form-input rounded-[8px] w-full text-16 leading-[30px] py-[12px] pl-[16px] pr-[60px] h-[48px] border-border-gray focus:border-theme-blue focus:ring-0",
            {
              ["border-theme-error"]: !!error,
            },
            className
          )}
          placeholder={placeholder}
        />
        {type === "password" && (
          <div className="absolute top-[12px] right-[22px] cursor-pointer" onClick={changeType}>
            {typeState === "password" ? <Eye /> : <EyeCrossed />}
          </div>
        )}
        {error && (
          <p className="text-theme-error text-12 leading-[20px] mt-[6px]">
            {error.toString()[0].toUpperCase() + error.slice(1)}
          </p>
        )}
      </div>
    </label>
  );
};
