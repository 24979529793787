import { useCallback } from "react";

export const useAnchor = () => {
  // accept # achor id prop in dom node, and trying to scroll for it
  return useCallback((achor: string) => {
    const element = document.getElementById(achor);
    if (!element) return false;
    const { top } = element.getBoundingClientRect();
    window.scrollTo({ top });
  }, []);
};
