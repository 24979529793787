import { ControlledInput } from "Components/controlled-ui/ControlledInput";
import { PopUp } from "Components/PopUp/PopUp";
import { RoundCheck } from "Images/Icons/RoundCheck";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesEnum } from "Routes/routesEnum";
import { recoverPassword } from "Services/auth-service";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AuthBlock } from "Components/AuthBlock/AuthBlock";
import { FormBlock } from "Components/Form/FormBlock";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

export const recoverPasswordSchema = yup.object().shape({
  email: yup.string().required("This field is required").trim().email(),
});

export const RecoverPassword = () => {
  const navigate = useNavigate();
  const [showPopUp, setShowPop] = useState(false);
  const { control, trigger, handleSubmit, setError, getValues } = useForm<{ email: string }>({
    resolver: yupResolver(recoverPasswordSchema),
  });

  const onSubmit = async (formData: { email: string }) => {
    const valid = await trigger();
    if (!valid) return;
    const { response } = await recoverPassword(formData);

    if (response && (response.status === 400 || response.status === 404)) {
      setError("email", { type: "custom", message: response.data.message });
      return;
    }
    setShowPop(true);
  };

  return (
    <div>
      <Helmet>
        <title>Petabox: Recover password</title>
        <meta name="description" content="Petabox: Recover password" />
      </Helmet>
      <AuthBlock>
        <FormBlock
          title="Recover password"
          subTitle="Enter the email you used to register and we’ll send you the instruction"
          submitHandler={handleSubmit(onSubmit)}
          submitText="Reset password"
        >
          <div className="grid grid-cols-1 gap-[24px] mb-[24px]">
            <ControlledInput type="email" control={control} name="email" required label="Email" />
          </div>
        </FormBlock>
      </AuthBlock>
      <PopUp
        title="Message sent"
        show={showPopUp}
        icon={<RoundCheck />}
        description={`We sent an email to ${getValues("email")}
            with a link to restore access to your account`}
        actionTitle="Ok"
        action={() => navigate(routesEnum.signin)}
      />
    </div>
  );
};
