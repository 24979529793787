export const RoundCheck = () =>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="39.5" fill="#E56399" fillOpacity="0.04" stroke="#E56399"/>
        <mask id="mask0_70_9923" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="26" y="29" width="29" height="21">
            <path d="M35.3001 45.846L29.6126 40.1585C29.3122 39.8544 28.9026 39.6832 28.4751 39.6832C28.0477 39.6832 27.638 39.8544 27.3376 40.1585C26.7039 40.7922 26.7039 41.7997 27.3376 42.4335L34.1464 49.2422C34.7801 49.876 35.8039 49.876 36.4376 49.2422L53.6626 32.0335C54.2964 31.3997 54.2964 30.3922 53.6626 29.7585C53.3622 29.4544 52.9526 29.2832 52.5251 29.2832C52.0977 29.2832 51.688 29.4544 51.3876 29.7585L35.3001 45.846Z" fill="black"/>
        </mask>
        <g mask="url(#mask0_70_9923)">
            <rect x="21" y="20" width="39" height="39" fill="#E56399"/>
        </g>
    </svg>
